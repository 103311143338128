.unassigned-users {
    width: 100%;

    .table-actions {
        display: flex;
        flex-direction: row;
    }
}

.user-hierarchy {
    width: 100%;
}

table, th, td, tr {
    margin: 0;
    padding: 0;
}