.coming-soon {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    background-color:white;
    padding: 35px;
    border-radius: 8px;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  }