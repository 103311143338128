.staff-dashboard-container {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
}

.maincontainer {
  height: 100%;
  width: 100%;
}

.tubInbox_pp_nm_discarded {
  color: #00AFEE !important;
}

.sticky-tab {
  position: sticky;
  top: 80px;
  z-index: 3;
}

.sticky_bx_shadow {
  box-shadow: 0px 6px 5px #0000001d !important;
}

.px-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.tb_f_1 {
  color: #00afee;
  font-weight: 500;
}

.fs-15 {
  font-size: 15px;
}

.fs-10 {
  font-size: 10px;
}

.fs-11 {
  font-size: 11px;
}

.fs-12 {
  font-size: 12px;
}

.pt-10 {
  padding-top: 10px;
}

.mt10 {
  margin-top: 10px;
}

.float-left {
  float: left;
}

.Toastify__toast-body {
  white-space: pre-line !important;
}

.assignment_text_1 {
  color: #949494;
  font-size: 13px;
  font-weight: 500;
  text-align: right;
}

.dashboard-col {
  width: 100%;
}

@media (min-width: 991px) {
  .dashboard-col {
    margin: 80px 130px;
  }
}

@media screen and (max-width: 1150px) {
  .options_margin {
    margin: 1rem 0.5rem 0 0.5rem;
  }

  .optionItem {
    width: 300px;
    height: 250px;
    margin: 0 auto;
  }

  .mis-letter-option {
    font-size: 30px !important;
  }
}

@media screen and (min-width: 1151px) {
  .options_margin {
    margin: 3rem 0.5rem 0 0.5rem;
  }

  .optionItem {
    width: 300px;
    height: 250px;
    margin: 0 auto;
  }

  .mis-letter-option {
    font-size: 45px !important;
  }
}

@media (max-width: 992px) {
  .dashboard-col {
    margin: 80px 10px;
  }
}

@media (min-width: 1900px) {
  .dashboard-col {
    margin: 80px 160px;
  }
}

.justify-end {
  justify-content: flex-end;
}

.h-100vh {
  height: 100vh;
}

.h100 {
  height: 100%;
}

.h97 {
  height: 97%;
}

.theme-bg {
  background-color: #f5f5f5 !important;
}

.theme-border {
  border: 1px solid #f5f5f5 !important;
}

.privacy_end_border {
  width: 75%;
  margin: 0 auto;
  border-bottom: 1px solid #c8c2c2 !important;
}

.text-align-center {
  text-align-last: center !important;
}

.navbar {
  position: relative;
  position: fixed;
  width: 100%;
  min-height: 80px;
  z-index: 10;
  min-height: 80px;
  padding: 0px 15px;
  border: 1px solid transparent;
  background: #00afee;
  color: #ffffff !important;
}

.auth-navbar {
  position: relative;
  width: 100%;
  min-height: 80px;
  padding: 0px 15px;
  border: 1px solid transparent;
  background: #00afee;
  color: #ffffff !important;
}

.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand {
  color: rgba(255, 255, 255, 0.9) !important;
}

.navbar-brand {
  display: flex;
  margin-right: 0px !important;
}

.w130-px {
  width: 130px;
}

@media (min-width: 768px) {
  .navbar-nav {
    margin-left: 10px;
  }
}

@media (max-width: 768px) {

  .navbar-light .navbar-nav .nav-link:focus,
  .navbar-light .navbar-nav .nav-link {
    margin: 5px 0px;
  }
}

.nav-link {
  padding: 0.57rem 1rem !important;
  color: #fff !important;
}

.side-nav .nav-list-menu {
  margin: 0px auto 25px auto;
}

.right-side-nav .nav-list-menu {
  margin: 10px auto;
}

.mx-130 {
  margin: 0px 130px;
}

.nav-view-content {
  margin: 5px 0px;
}

.nav-view-content h5 {
  color: #F05A28;
  font-size: 14px !important;
  font-family: inherit;
  padding: 0 10px;
}

.launch-class {
  background: #F05A28;
  border-radius: 5px;
  padding: 10px 0px;
  cursor: pointer;
}

.disable_launch {
  opacity: 0.4;
}

.rightbarinner {
  padding: 0 10px;
  height: calc(100% - 80px);
  max-height: calc(100% - 80px);
  overflow-y: scroll;

}

.launch-class h5 {
  margin: 10px 0px 0px 0px !important;
  color: #ffffff;
  font-weight: 600;
  font-family: inherit;
  font-size: 14px !important;
  text-align: center;
}

.launch-class p {
  color: #ffffff;
  font-weight: 600;
  font-family: inherit;
  font-size: 14px !important;
  text-align: center;
}

.right-tubtext {
  font-size: 16px !important;
  font-family: inherit;
  font-weight: 700;
}

.righttub-members h5 {
  font-family: inherit;
  font-size: 14px !important;
  text-align: center;
  margin-top: 5px;
}

.logo-text {
  font-size: 18px;
  color: #fff;
  font-weight: bold;
  margin-left: 20px;
}

.studentProfileImg {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  // object-fit: cover;
}

.right_sidebar_student_image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}

.right_sidebar_student_image:hover {
  border: 2px solid #00afee;
}

.active_student_image {
  border: 2px solid #00afee;
}

.flex-12 {
  flex: 12;
}

.navtopright-content {
  margin-left: 15px !important;
  justify-content: flex-end;
}

// .topright-inner {
// padding: 0px 0px 0px 20px;
// }
.notificatioin-icon {
  width: 45px;
  height: 45px;
}

.nav_class_dropdown {
  width: 200px;
}

.relative-pos {
  position: relative;
}

.search-icon {
  position: absolute;
  right: 11px;
  top: 7px;
  z-index: -1;
}

.topbarsearchinput {
  width: 100%;
  height: 36px;
  background: #ffffff;
  border: 1px solid #ced4da;
  box-sizing: border-box;
  border-radius: 15px;
}

.color-fade {
  color: #B0B0B0;
}

.ml-20 {
  margin-left: 20px;
}

.navbar-right a {
  font-size: 18px !important;
  color: #fff;
}

.nav-link-text {
  font-size: 15px;
  font-style: normal;
  font-family: inherit;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff !important;
}

.nav-link-text .btn-primary {
  background-color: #00afee !important;
  color: #ffffff !important;
  font-size: 15px;
  font-style: normal;
  font-family: inherit;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
}

.nav-link-text:hover {
  color: #F05A28 !important;
}

.tubstore:hover {
  color: #F05A28 !important;
}

.tubstore {
  background: #fff;
  color: #00afee !important;
  border-radius: 11px;
  font-weight: 500;
  font-size: 15px;
  font-family: inherit;
  text-align: center;
  box-shadow: 1px 1px 14px #0080ae;
  padding-left: 20px !important;
  padding-right: 20px !important;
  line-height: 27px
}


.navbar-nav>li>a {
  margin-top: 10px;
}


.nav>li>a:focus,
.nav>li>a:hover {
  text-decoration: none;
  background-color: transparent;
  color: #000;
}

@media (min-width: 768px) {
  .navbar-nav>li {
    float: left;
    margin-left: 30px;
    margin-right: 30px;
  }
}

img {
  max-width: 100%;
}

.text-input {
  width: 100%;
  background: #e9ecef;
  border: 1px solid #ced4da;
  box-sizing: border-box;
  border-radius: 4px;
}

.login-text-login {
  text-align: center;
}

.welcome-text {
  text-align: center;
  color: #000;
  font-size: 17px;
  margin: 20px 0;
}

.form-group {
  margin-bottom: 30px;
  float: left;
  width: 100%;
}

.proceed-button {
  margin: 0 auto;
  text-align: center;
}

.w-200px {
  width: 200px;
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  border: 2px solid transparent;
  font-size: 14px;
  line-height: 1.5;
  font-family: inherit;
  background: #ffffff !important;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.alignself-flex-end {
  align-self: flex-end;
}

.btn-primary {
  color: #00afee !important;
  border-color: #00afee;
}

.btn-primary.focus,
.btn-primary:focus {
  color: #00afee;
  background-color: #fff;
  border-color: #00afee;
}

.btn-primary:hover {
  color: #fff !important;
  background-color: #00afee !important;
  border-color: #00afee
}

.btn-primary.active,
.btn-primary:active,
.open>.dropdown-toggle.btn-primary {
  color: #fff;
  background-color: #00afee;
  background-image: none;
  border-color: #00afee
}

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary:active.focus,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open>.dropdown-toggle.btn-primary.focus,
.open>.dropdown-toggle.btn-primary:focus,
.open>.dropdown-toggle.btn-primary:hover {
  color: #fff;
  background-color: #00afee;
  background-image: none;
  border-color: #00afee
}

.mtb-30 {
  margin: 30px 0;
}

.mt-30 {
  margin: 30px 0;
}

.mt-20 {
  margin-top: 20px;
  float: left;
}

.forgot-link {
  font-size: 14px;
  color: #007afd;
}

.navbar-toggle {
  background: #fff;
}

.navbar-toggle .icon-bar {
  background: #000;
}

.container-fluid>.navbar-collapse,
.container-fluid>.navbar-header,
.container>.navbar-collapse,
.container>.navbar-header {
  margin-left: 0;
}

@media screen and (max-width: 991px) {
  a.tubstore {
    background: #fff;
    color: #00afee;
    border-radius: 0;
    box-shadow: none;
    width: 200px;
    margin: 0 auto;
  }

  .navbar-nav {
    margin-right: 0;
  }

  .auth-navbar {
    padding-bottom: 15px;
  }
}

.right {
  float: right;
}

p {
  margin: 0;
  padding: 0;
}

.d-none {
  display: none;
}

.network-disconnected {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 3;
  background-color: #000000;
  opacity: 0.6;
  width: 100%;
  height: 100vh;
  transform: translate(-50%, -50%);
}

.connection {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 3;
}

.discon {
  font-size: 24px !important;
  font-weight: 400;
  color: #ffffff;
}

.network-detector {
  position: absolute;
}

.error-msg {
  margin: 3px 0px;
  color: red !important;
  font-size: 12px;
  font-family: inherit;
  font-weight: 400;
}

.login-text {
  font-family: images;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
  text-shadow: 4px 4px 50px rgba(0, 0, 0, 0.5);
}

.mt-10 {
  margin-top: 5%;
}

.new_to {
  color: #000000;
  font-weight: 400;
  font-family: inherit;
  font-size: 15px !important;
}

.auth-toggle-link {
  color: #0550E3;
  cursor: pointer;
  font-weight: 400;
  font-family: inherit;
  font-size: 13px;
}

.login-text-login {
  font-family: inherit;
  font-style: normal;
  font-size: 18px !important;
  font-weight: 500;
  color: #000000;
}

.login-text-wlcm {
  font-family: inherit;
  font-style: normal;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  align-items: center;
  text-align: center;
  color: #000000 !important;
  margin-top: 10px;
  // text-shadow: 4px 4px 50px rgba(0, 0, 0, 0.5);
}

.text-input {
  width: 100%;
  background: #e9ecef;
  border: 1px solid #ced4da;
  box-sizing: border-box;
  border-radius: 4px;
}

.subscriptionborder {
  border-radius: 10px !important;
}

.auth-background {
  background: #f8f9fa !important;
}

.subscrip-bg {
  background: rgba(255, 255, 255, 0.46) !important;
}

.auth-bgImage {
  height: auto;
  max-width: 100%;
}

.maincontainer {
  padding: 1%;
}

.mt-login {
  font-family: inherit;
  font-style: normal;
  font-weight: 400;
  font-size: 12px !important;
  line-height: 24px;
  color: #212529;
  text-align: left;
}

.proceed {
  position: static;
  width: 105px;
  height: 30px;
  left: 2.5px;
  top: 0px;

  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 21px;
  /* identical to box height, or 191% */

  align-items: center;
  text-align: center;

  color: #00afee;
  border-color: #00afee;
  border-radius: 5px;
  /* Inside Auto Layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 4px;
}

.custom-control {
  position: static !important;
}

.password-input {
  position: relative;
}

.password-icon {
  width: 20px;
  cursor: pointer;
}

.password-img {
  position: absolute;
  right: 10px;
  top: 7px;
}

.remember-checkbox {
  vertical-align: middle;
  width: 15px;
  height: 15px;
}

.checkboxLabel {
  margin-left: 5px;
  margin-top: 1px;
}

// basic tabs 
.nav-tabs {
  border: 0;
  margin: 10px 0px;
  background: #ffffff;
}

.nav-tabs .nav-link.active {
  background-color: #ffffff;
  border-bottom: 4px solid #00AFEE !important;
  border-top-color: #ffffff;
  border-left-color: #ffffff;
  border-right-color: #ffffff;
  text-align: center;
}

.nav-link.active .tab-text {
  color: #00AFEE;
}

.nav-tabs .nav-link {
  text-align: center;
  margin: 0px !important;
  cursor: pointer;
  border: none;
}

.tab-text {
  font-family: inherit;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #000000;
}

.terms-heading {
  font-size: 32px !important;
  font-family: inherit;
  font-weight: 500;
}

.privacy-content {
  text-align: justify;
}

.react-tel-input .form-control {
  width: 100% !important;
  background: #e9ecef !important;
  border: 1px solid #CED4DA !important;
  height: 34px !important;
}


// classes 
.w-96 {
  width: 96%;
}

.alphatub-card-h6 {
  font-family: inherit;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500;
}
.alp_card_p {
  font-family: inherit;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400;
    color: #6e6c6c;
}
.DateRangePicker {
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 0 10px #d1d1d1;
}

.DateRangePicker .DateRangePicker__Month {
  margin: 0 13px;
  width: -webkit-fill-available;
}

.classTable {
  box-shadow: 1px 1px 3px #0000001d;
  padding: 12px !important;
  border-radius: 5px;
  background-color: #ffffff;
}

// .custom_class_table table {
//   table-layout: auto !important;
//   word-break: break-all;
// }
.classes-seats-link {
  font-family: inherit;
  font-style: normal;
  font-weight: 300;
  color: #5A8FF4;
  cursor: pointer;
  font-size: 12px !important;
}

.custom-row-class td {
  border: 0px !important;
  font-weight: 300;
  font-size: 13px;
  vertical-align: middle;
  font-family: inherit;
}

.manageseats_table td {
  border: 0px !important;
  vertical-align: middle;
}

// .manageseats_table td:nth-child(3),:nth-child(4),:nth-child(5),:nth-child(6) {
// text-align: center;
// }
.roundBlue {
  background: #00AFEE;
  width: 10px;
  height: 9px;
  border-radius: 50%;
}

.roundRed {
  background: #F05A28;
  width: 10px;
  height: 9px;
  border-radius: 50%;
}

.bootstrap-table {
  border: 0px !important;
}

.bootstrap-table .table-bordered {
  border: 0px !important;
}

.team_right_content {
  padding: 10px 10px 10px 15px;
  border-radius: 5px;
  background-color: #ffffff;
  height: 100%;
}

.team-emailinput {
  // background: rgba(255, 255, 255, 0.46) !important;
  border: none !important;
  background: #F1F1F1 !important;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.team-roleinput {
  // background: rgba(255, 255, 255, 0.46) !important;
  border: none !important;
  background: #F1F1F1 !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}


// calendar design

.react-calendar {
  background: #F5F5F5 !important;
  box-shadow: 0px 0.5px 3px 0px rgba(0, 14, 51, 0.25) !important;
  border-radius: 16px !important;
  border: none !important;
  padding: 5px 10px 10px 10px;

}


.react-calendar__navigation button {
  background-color: #ffffff !important;
  // min-width: 40px !important;
  margin: 1px !important;
}

.react-calendar__navigation__prev2-button {
  border-radius: 50px !important;
  display: none;
}

.react-calendar__navigation__prev-button {
  border-radius: 50px !important;
  min-width: 30px !important;
  height: 30px !important;
  font-size: 30px;
  padding: 0px 0px 3px 2px;
}

.react-calendar__navigation__next2-button {
  border-radius: 50px !important;
  display: none;
}

.react-calendar__navigation__next-button {
  border-radius: 50px !important;
  min-width: 30px !important;
  height: 30px !important;
  font-size: 30px;
  padding: 0px 0px 3px 2px;
}

.react-calendar__navigation__label {
  flex-grow: 0 !important;
}

.react-calendar__month-view__weekdays__weekday abbr {
  font-family: 'Poppins';
  font-size: 10px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
}

// .react-calendar__tile:enabled:hover
.react-calendar__navigation__label {
  border-radius: 6px !important;
}

.react-calendar__navigation__label__labelText {
  font-family: 'Poppins';
  font-size: 11px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
}

.p-bold-text11 {
  font-family: inherit;
  font-size: 11px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: right;
}

.addnote {
  font-family: inherit;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: 0em;
  color: #0550E3;
  text-align: right;
  cursor: pointer;
}


.signupEmailLabel {
  margin: 0 !important;
  padding: 0.375rem 0.75rem;
  height: calc(1.5em + 0.75rem + 2px);
}

.terms-bluetext {
  color: #0550E3;
}

.popup-close-btn {
  background: #F05A28 !important;
  border: 1px solid #F05A28 !important;
  color: #ffffff !important;
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  padding: 0.375rem 1.25rem;
  font-size: 14px;
  line-height: 1.5;
  cursor: pointer;
  width: 100%;
  font-family: inherit;
  border-radius: 0.25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.passwordPolicyContent {
  font-family: inherit;
  font-size: 10px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: justify;
  color: #B0B0B0;
}

.left-text {
  text-align: left
}

.resend-request {
  color: #00AFEE !important;
  font-family: inherit;
  font-size: 11px !important;
  font-style: normal;
  font-weight: 300 !important;
  line-height: 17px;
  letter-spacing: 0em;

}

.f-12 {
  font-size: 12px !important;
}

.tub-seeall {
  color: #5071A3 !important;
  font-family: inherit;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
}

// gallery design
.stencilSize {
  width: 40px;
  height: 40px;
}

.session_spnimg {
  position: absolute;
  top: -15px;
  left: 7px;
}

.gallery-cal .react-calendar {
  margin: 0 auto;
}



.react-tel-input {
  font-family: inherit;
}

// notification design

.notification_type {
  height: 400px;
}

.notification_type::before {
  content: '';
  width: 0px;
  height: 0px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 30px solid #00afee;
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  right: 28px;
  top: -17px;
  margin: 0 auto;
}

.notification_header {
  background-color: #00afee;
  height: 30px;
}

.notification_type_drop .dropdown-menu {
  background-color: #00afee;
  border: none;
  width: 400px;
  left: -327px;
  top: 90px;
  margin: 0;
  padding: 0 !important;
}

.notification_body {
  height: 370px;
  background-color: #ffffff;
  overflow-y: scroll;
  padding: 10px 15px;
}

.notification_inner {
  margin-top: 10px;
}

.notification_inner .buttonType1 {
  font-size: 11px;
  font-weight: 600;
  background-color: #00afee !important;
  color: #ffffff !important;
  width: 65px;
  border-radius: 15px;
  padding: 0 !important;
  margin-left: auto;
  line-height: 14px;
  height: 18px;
}

.notification_content img {
  width: 60px;
  height: 60px;
}

.notification_headers {
  padding: 10px;
  width: 300px;
}

.notification_content {
  display: flex;
  align-items: flex-start;
}

.notification_headers p {
  font-size: 15px;
  line-height: 17px;
  font-family: inherit;
  white-space: break-spaces;
  font-weight: 400;
  color: #949494;
}

.notification_headers strong {
  font-size: 14px;
  line-height: 17px;
  font-family: inherit;
  font-weight: 600;
  color: #000000;
}

.notification_content_border {
  margin-left: auto;
  height: 1px;
  background-color: #949494;

}

.notification_time p {
  text-align: right;
  font-size: 12px;
  color: #949494;
  font-family: inherit;
}

.btn_selected {
  background: #00afee !important;
  color: #ffffff !important;
}

.selectedAlphabet {
  color: #00afee !important
}

.whbr-60 {
  width: 60px !important;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
}

.whbr-50 {
  width: 50px !important;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
}

.pt-15 {
  padding-top: 15px;
}

.object_student_name {
  font-size: 12px;
  font-family: inherit;
  font-weight: 500;
  padding-bottom: 0.5rem !important;
}

.session_object_student_name {
  font-size: 9px;
  font-family: inherit;
  font-weight: 500;
  padding-bottom: 0.5rem !important;
}

.session_object_tag_name {
  font-size: 9px;
  font-family: inherit;
  font-weight: 500;
  padding: 0px 6px;
  height: 12px;
  line-height: 12px;
}

.pb-10 {
  padding-bottom: 10px;
}

.wh-50 {
  width: 50px;
  height: 50px;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
}

.sc-dnqmqq {
  margin: 3px !important;
}

.inbox-content-spnImg {
  position: absolute;
  left: 5px;
  top: -35px;
}

.pb-15 {
  padding-bottom: 15px;
}

.selectedType2Oject {
  position: absolute;
  background-color: #B0B0B0;
  width: 100%;
  opacity: 0.6;
  height: 100%;
  top: 0;
  border-radius: 10px;
}

.unSelectTubObject {
  position: absolute;
  top: 7px;
  right: -8px;
  z-index: 1;
}

.typeTwoImage:nth-of-type(1n+4) {
  display: none;
}

.mainsession {
  height: 93.5%;
}

.typeTwoImage:nth-child(1) {
  width: 87.5%;
  position: absolute;
  z-index: 3;
  height: 100%;
}

.typeTwoImage img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.typeTwoImage:nth-child(2) {
  width: 94%;
  position: absolute;
  z-index: 2;
  height: 100%;
}

.typeTwoImage:nth-child(3) {
  width: 100%;
  height: inherit;
}

.player_img {
  border-radius: 50%;
  width: 100px;
  height: 100px;
}

.wh-23 {
  width: 23px;
  height: 23px;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
}

.session_tdy_object {
  width: 170px;
  height: 130px;
}

.highlighted_letter {
  font-size: 26px !important;
  height: 20px;
  line-height: 20px;
}

.deleted_inbox {
  padding: 55px 11px 2px 11px;
}

.content_main {
  min-height: 240px;
}

.object_size {
  // min-width: 345px;
  height: 240px;
}

.mySessionObjectSize {
  height: 115px;
}

.class_presentation_object_main {
  height: 245px;
  width: 245px !important;
}

.class_presentation_object_main img {
  height: 100%;
}

.explore_view_object_size {
  width: 430px;
  height: 305px;
}

.whbrbx-75 {
  width: 75px !important;
  height: 75px;
  border-radius: 50%;
  box-shadow: 3px 6px 4px 1px rgba(0, 0, 0, 0.25);
}

.tub_inbox_inner_card {
  height: 400px;
}

.tub_inbox_std {
  position: absolute;
  top: -37px;
  left: 15px;
}

.explore_std_detail {
  position: absolute;
  top: -43px;
  left: 8px;
}

.ib_pending_img {
  height: inherit;
  width: 100%;
}

.w_50 {
  width: 50%;
}

.h_100 {
  height: 100%;
}

.width_one_third {
  width: 33.33333%;
}

.fit_cover {
  object-fit: cover;
}

.tb_inbox_container {
  column-count: 3;
  column-gap: 0;
}

.tb_inbox_container_cols_2 {
  column-count: 2;
  column-gap: 0;
}

.tb_inbox_container_cols_32 {
  height: 384px !important;
}

.ib_pending_full_h {
  height: 100%;
}

.hidden_text {
  visibility: hidden;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide {
  display: flex;
  justify-content: center;
}

.wh-50 {
  width: 50px !important;
  height: 50px !important;
}

.br-10 {
  border-radius: 10px;
}

.position-absolute {
  position: absolute;
}

.position-relative {
  position: relative;
}

.showLeftSidebar {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 11px 10px #0000001d;
  opacity: 1;
  display: flex !important;
  border-right: 1px solid gainsboro;
  width: 8.5rem;
  position: fixed;
  height: 100%;
  margin: 80px 0px;
  left: 0;
  z-index: 1;
}

.showRightSidebar {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 11px 10px #0000001d;
  opacity: 1;
  display: flex !important;
  border-right: 1px solid gainsboro;
  width: 8.5rem;
  position: fixed;
  height: 100%;
  margin: 80px 0px;
  right: 0;
  z-index: 1;
}

@media screen and (max-width: 992px) {
  .session-calendar .react-calendar {
    width: 100% !important;
  }

  .session_activities {
    margin-top: 15px;
  }

  .flSNVE:last-of-type> :last-of-type {
    padding: 0px !important;
  }

  .jeclxv {
    padding: 0px !important;
    grid-template-columns: repeat(1, 100%);
  }

  .flSNVE {
    grid-template-columns: repeat(2, 50%) !important;
    grid-template-rows: 1fr;
    grid-gap: 0px !important;
  }

  .sc-ifAKCX .sc-EHOje {
    margin: 0px;
  }

  .privacy_acc {
    margin-top: 10px;
  }

  .react-calendar {
    width: 100% !important;
  }

  .class_left_container {
    width: 100%;
  }

  .profie_right_container {
    margin-top: 20px;
    background-color: #ffffff;
  }

  .profile_right_inner {
    padding: 20px 0px;
  }

  .nav-search {
    display: none;
  }

  .basic-navbar-nav {
    display: flex;
  }

  .adminlogo-text {
    display: none;
  }

  .bottom_tab_container {
    position: fixed;
    bottom: 0;
    height: 50px;
    background: #ffffff;
    width: 100%;
    z-index: 5;
    box-shadow: 0px 1px 15px #0000001d;
  }

  .leftsidebar {
    display: none;
  }

  .rightsidebar {
    display: none;
  }
}

@media screen and (min-width: 993px) {
  .assignment_calendar {
      height: 100%;
      width: 93%!important;
  }
  .session-calendar .react-calendar {
    width: 93% !important;
    height: 100%;
  }

  .tub-acts-session .sc-ifAKCX .sc-EHOje {
    margin: 0px 35px;
  }

  .upcoming_session_container .bghjHX {
    margin: 0 !important;
  }

  .upcoming_session_container .eJawEs {
    margin: 0 !important;
  }

  .static_nav {
    margin-left: 10px;
  }

  .class_left_container {
    width: 96%;
  }

  .profie_right_container {
    border-radius: 5px;
    box-shadow: 1px 1px 3px #0000001d;
    background-color: #ffffff;
  }

  .profile_right_inner {
    padding: 20px 5px;
  }

  .nav-search {
    flex: 12;
    justify-content: flex-end;
  }

  .adminlogo-text {
    font-size: 24px;
    color: #fff;
    font-weight: bold;
  }

  .staff-dashboard-container .side-nav {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 2px 11px 10px #0000001d;
    opacity: 1;
    display: flex;
    border-right: 1px solid gainsboro;
    width: 8.5rem;
    position: fixed;
    height: 100%;
    margin: 80px 0px;
    left: 0;
    z-index: 1;
  }

  .staff-dashboard-container .right-side-nav {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 2px 11px 10px #0000001d;
    opacity: 1;
    display: flex;
    border-right: 1px solid gainsboro;
    width: 8.5rem;
    position: fixed;
    right: 0;
    margin: 80px 0px;
    height: 100%;
    z-index: 1;
  }

  .bottom_tab_container {
    display: none;
  }
}

.bt_tab_inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 25px;
  height: inherit;
}

.bt_tab_img {
  width: 30px;
  height: 30px;
}

.clp_arrow {
  width: inherit;
  height: inherit;
}

.arrowRightStyles {
  position: absolute;
  z-index: 1;
  top: calc(50% - 25px);
  right: 0;
  width: 16px;
  height: 16px;
  cursor: pointer
}

.object-detail-list img {
  border: 2px solid #717171;
}

.object-detail-list:hover img {
  border: 2px solid #00afee;
}

.arrowLeftStyles {
  position: absolute;
  z-index: 1;
  top: calc(50% - 25px);
  left: 0;
  width: 16px;
  height: 16px;
  cursor: pointer
}

.show_more {
  font-family: inherit;
  font-size: 9px;
  color: #666666;
  cursor: pointer;
}

.auth-toggle-link:hover {
  color: #F05A28;
}

.card_img {
  border-radius: 10px;
  border: 1.5px solid #00afee;
}

.w-230 {
  width: 230px;
  margin: 0 auto;
}

.session_colec_name {
  font-family: inherit;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
  color: #B0B0B0;
}

.selected_activity_count {
  font-family: inherit;
  font-weight: 600;
  font-size: 16;
}

.mx-700 {
  max-width: 700px !important;
}

.mx-900 {
  max-width: 900px !important;
}

.mx-800 {
  max-width: 800px !important;
}

.ReactCrop__child-wrapper {
  height: 400px;
  overflow: scroll;
}

.ReactCrop__child-wrapper img {
  width: 100%;
  height: inherit;
}

.profile_popup {
  padding: 0px !important;
}

.carousel img {
  pointer-events: all !important;
}

.session_count_container {
  position: absolute;
  right: 0;
  top: 0
}

.session_count_inner {
  background-color: #F05A28;
  width: 19px;
  height: 23px;
  border-radius: 4px;
}

.session_count_inner p {
  color: #ffffff;
  font-size: 12px;
  font-family: inherit;
  font-weight: 600;
  line-height: 23px;
}

.session_arrow_right {
  right: -13px;
}

.session_arrow_left {
  left: -13px;
}

.clp_arrow_right {
  right: 0px;
}

.clp_arrow_left {
  left: 0px;
}

.assignment_content_container {
  width: 85px;
  position: relative;
}

.assignment_content_inner {
  background-color: #c4c4c499;
  width: 85px;
  height: 85px;
  border-radius: 5px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.assignment_content_container p {
  color: #000000;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
}

.column-gap-20 {
  column-gap: 20px;
}

.assignment_selected {
  position: absolute;
  top: -10px;
  right: -7px;
  width: 15px;
  height: 20px;
}

.left-arrow {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #000000;
}

.right-arrow {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #000000;
}

.deadline_date {
  color: #00afee;
  font-size: 14px;
}

.gallery_letter {
  color: #A6A6A6;
  font-size: 18px;
  font-weight: 600;
}

.table-theme tbody tr:nth-child(odd) .custom-select:disabled {
  background-color: #ffffff !important;
}

.custom-select {
  text-align: left !important;
}

.custom-select:disabled {
  background-color: #f2f2f2 !important;
}

.disabled_tab {
  background-color: #f2f2f2;
  height: 42px;
  cursor: not-allowed !important;
}

.disabled_tab:hover {
  background-color: #f2f2f2 !important;
}

.disabled_tab span {
  color: #666666;
}

.h_94 {
  height: 93.5%;
}

.h_94_half {
  height: 94.5%;
}

.h_83 {
  height: 83%;
}

.h_86 {
  height: 86%;
}

.rejected_inbox_content {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.rejected_inbox_pending_container {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.5;
  top: 0%;
  left: 50%;
  transform: translateX(-50%);
}

.inbox_Left {
  position: absolute;
  z-index: 1;
  top: calc(50% - 50px);
  left: 0;
  width: 60px;
  line-height: 60px;
  height: 60px;
  background: #ffffff;
  cursor: pointer;
  text-align: center;
  border-radius: 50%;
}

.inbox_Right {
  position: absolute;
  z-index: 1;
  top: calc(50% - 50px);
  right: 0;
  width: 60px;
  line-height: 60px;
  height: 60px;
  background: #ffffff;
  cursor: pointer;
  text-align: center;
  border-radius: 50%;
}

.disclaimer_container {
  width: 80%;
  text-align: right;
  position: fixed;
  bottom: 20px;
  right: 31px;
}

.disclaimer_container p {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
}

.d-inline-block {
  display: inline-block;
}

.d-inline {
  display: inline;
}

.tub_card_layout_container {
  display: flex;
  flex-wrap: wrap;
}

.flex-col-divide-3 {
  width: 33.333%;
  flex-basis: 33.333%;
}

.saveBtnType3Disabled {
  opacity: 1;
  background-color: #ffffff !important;
  border: 2px solid #bec0c2 !important;
  color: #000000 !important;
  border-radius: 5px;
  padding: 5px 10px;
  text-align: center;
}

.fixed_scrollbtn {
  position: fixed;
  bottom: 20px;
  right: 150px;
  z-index: 1000;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fixed_scrollbtn img {
  width: 16px;
  height: 16px;
}

.tub-session-category {
  word-break: break-word;
  font-size: 11px;
  font-family: inherit;
}
// apex charts
.apexcharts-legend.apx-legend-position-top .apexcharts-legend-series {
  display: flex !important;
  align-items: center !important;

  span {
    font-family: inherit !important;
  }
}
.apexcharts-toolbar {
  z-index: 2 !important;
}

.assignment_status_container {
  background: #F5F5F5;
  box-shadow: 0px 0.5px 3px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 20px;

  .legend_container {
    display: flex;
    justify-content: space-between;

    .legend {
      display: flex;
      align-items: center;
      margin-left: 20px;

      .legend_identifier {
        width: 8px;
        height: 8px;
        border-radius: 50%;
      }

      .legend_text {
        margin-left: 7px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: #aaaaaa;
      }
    }
  }


  .assignment_track_table {
    table {
      thead tr {
        background-color: #495057;

        th {
          color: #ffffff;
          font-style: normal;
          font-weight: 500;
          font-size: 11px;
          padding: 7px;
          border: none !important;
        }

        th:nth-child(1) {
          text-align: center;
        }

        th:nth-child(3) {
          text-align: center;
        }

        th:nth-child(4) {
          text-align: center;
        }

        th:nth-child(5) {
          text-align: center;
        }

        th:nth-child(6) {
          text-align: center;
        }
      }

      tbody tr {
        td {
          border: none !important;
        }

        td:nth-child(1) {
          color: #000000;
          opacity: 0.5;
          text-align: center;
        }
        td:nth-child(2) {
          color: #000000;
          opacity: 0.5;
        }
        td:nth-child(3) {
          text-align: center;
        }

        td:nth-child(4) {
          text-align: center;
        }

        td:nth-child(5) {
          text-align: center;
        }

        td:nth-child(6) {
          text-align: center;
        }
      }
    }
  }
  
  
}
.assignment_tracker_table_text_1 {
  font-size: 13px;
  font-weight: 400;
  color: #495057;
}
.assignment_content_identifier {
  width: 8px;
  height: 8px;
  margin: 0 auto;
  border-radius: 50%;
}
// assignemnt tracking 

.assignment_track_header {
  background-color: #ffffff;
  padding: 15px;
  margin: 1rem 0rem;
}

.assignment_track_filter {
  width: 200px;
  background: #F5F5F5 !important;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 400;
  color: #000000;
}
.assignment_track_table {
  .table-striped tbody tr {
    background-color: #ffffff !important;
  }
  table {
    
    thead tr {
      background-color: #ffffff;

      th {
        color: #495057;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        padding: 10px;
        border-bottom: 1px solid #f4f1f1 !important;
      }
      th:nth-child(3) {
        text-align: center;
      }

      th:nth-child(4) {
        text-align: center;
      }
    }

    tbody tr {
      td {
        border-left: none !important;
        border-right: none !important;
        border-top: none !important;
        border-bottom:  1px solid #f4f1f1 !important;
      }

      td:nth-child(3) {
        text-align: center;
      }

      td:nth-child(4) {
        text-align: center;
      }
    }
  }
}

.clp_right_arrow_notch {
  width: 15px;
  height: 80px;
  display: flex;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  background-color: #f05a2880;
  position: absolute;
  z-index: 1;
  right: 0;
  top: calc(50% - 30px);
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.clp_left_arrow_notch {
  width: 15px;
  height: 80px;
  display: flex;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: #f05a2880;
  position: absolute;
  z-index: 1;
  left: 0;
  top: calc(50% - 30px);
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.clp_right_arrow_notch:hover {
    background-color: #f05a28;
}
.clp_left_arrow_notch:hover {
  background-color: #f05a28;
}

// reading program
.gap-1 {
  row-gap: 1px;
  column-gap: 1px;
}
.reading_program_images_container {
  width: 33%;
  position: relative;

  .content_inner {
    background-color: #ffffff;
    height: 85px;
    width: 98%;
    border-radius: 5px;
    padding: 1px;
    margin: 1px;
    align-items: center;
    display: flex;
    justify-content: center;

    img {
      height: 100%;
    }
  }
  .even-color {
    border: 1px solid #C0DBEA;
  }
  .odd-color {
    border : 1px solid #FDEFE9;
  }

}


.payment_status_container {
  width: 100%;
  max-width: 700px;
  background-color: #fff;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;


  .txt {
    font-size: 24px;
    font-family: inherit;
    font-weight: 700;
    margin-top:  50px;
    margin-left: 50px;
  }
}
.upcoming_session_container {
  margin: 0 auto;
}
@media (min-width: 700px) {
  .upcoming_session_container {
    max-width: 40rem;
  }
}

@media (min-width: 800px) {
  .upcoming_session_container {
    max-width: 50rem;
  }
}

@media (min-width: 900px) {
  .upcoming_session_container {
    max-width: 52rem;
  }
}
@media (min-width: 1100px) {
  .upcoming_session_container {
    max-width: 60rem;
  }
}

@media (min-width: 1200px) {
  .upcoming_session_container {
    max-width: 66.25rem;
  }
}

@media (min-width: 1300px) {
  .upcoming_session_container {
    max-width: 74.5rem;
  }
}
@media (min-width: 1400px) {
  .upcoming_session_container {
    max-width: 81.5rem;
  }
}