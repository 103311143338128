.unassigned-users {
    width: 100%;
    margin-top: 10px;

    .table-actions {
        display: flex;
        flex-direction: row;
    }
}

.unassigned-user-values {
    margin-top: 5px;
    margin-bottom: 5px;
}

.hierarcy-modal {
    min-width: 75%;
}

.user-hierarchy {
    width: 100%;
}