.dashboard_layout {
  padding: 0;
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.empty_data_text {
  font-size: 16px;
  font-weight: 500;
  font-family: inherit;
}

.table_empty_text {
  color: #7f7575;
  font-size: 13px;
  font-family: inherit;
}

.alp_text_4 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}

.alp_text_5 {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #aaaaaa;
}

.alp_text_link {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #0550e3;
}

.secondary_color {
  color: #f05a28;
}

.secondary_bg {
  background-color: #f05a28;
}

.tertiary_bg {
  background-color: #c4c4c4;
}

.global_primary_bg {
  background-color: #00afee;
}

.global_secondary_bg {
  background-color: #f05a28;
}

.global_disabled_bg {
  background-color: #c4c4c4;
}

.inner_app_loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
}

.w_70 {
  width: 70%;
}

@media (min-width: 1000px) and (max-width: 1100px) {
  .class_presentation .container {
    max-width: 60rem;
  }
}

@media (min-width: 1200px) and (max-width: 1300px) {
  .class_presentation .container {
    max-width: 67rem;
  }
}

@media (min-width: 1301px) and (max-width: 1400px) {
  .class_presentation .container {
    max-width: 73rem;
  }
}

@media (min-width: 1400px) and (max-width: 1529px) {
  .class_presentation .container {
    max-width: 80rem;
  }
}

@media (min-width: 1530px) {
  .class_presentation .container {
    max-width: 90rem;
  }
}

@media (min-width: 1600px) {
  .class_presentation .container {
    max-width: 75rem;
  }
}

.br-5 {
  border-radius: 5px;
}

.bg_white {
  background-color: #ffffff;
}

.card {
  border: none !important;
}

.justify-space-around {
  justify-content: space-around;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-flex-end {
  justify-content: flex-end;
}

.flex_all_property {
  display: flex;
  justify-content: center;
  align-items: center;
}

.w_200px {
  width: 200px;
}

.pl_2_rem {
  padding-left: 2rem;
}

.w_175px {
  width: 175px;
}

.pb-150 {
  padding-bottom: 150px;
}

.alp_dis_1 img {
  float: left;
}

.alp_dis_1 p {
  display: block;
}

.primary_bg {
  background-color: theme-color("primary");
}

.alp_text_success_1 {
  font-size: 13px;
  font-family: inherit;
  color: #00afee;
}

.alp_text_failure_1 {
  font-size: 13px;
  font-family: inherit;
  color: #f05a28;
  margin: 0px 0px 0px 5px;
}

.checkbox_color .flex_1 {
  flex: 1;
}

.flex_10 {
  flex: 10;
}

.row-gap-10 {
  row-gap: 10px;
}

.row-gap-20 {
  row-gap: 20px;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
}

.ptb-100 {
  padding: 100px 0px;
}

.mt-55 {
  margin-top: 55px;
}

.logo_loader {
  width: 40px;
  height: 40px;
  // -webkit-animation:spin 1s linear infinite;
  // -moz-animation:spin 1s linear infinite;
  // animation:spin 1s linear infinite;
}

.dropdown button {
  padding: 0;
}

.btn-secondary:hover {
  border-color: #ffffff !important;
}

.btn-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.hide_container .dropdown .dropdown-menu {
  left: -125px !important;
  transform: translate3d(0px, 28px, 0px) !important;
  box-shadow: none !important;
  background-color: #f5f5f5;
  border-radius: 5px;
}

.hide_container .dropdown .btn-secondary:focus {
  border-color: #ffffff !important;
}

.hide_container .dropdown .dropdown-menu .dropdown-item {
  padding: 5px 10px 5px 10px;
}

.hide_container .dropdown .dropdown-menu .dropdown-item:hover {
  border-bottom: none !important;
  background-color: #f5f5f5 !important;
  color: #00afee;
}

.tab-dropdown .dropdown-menu .dropdown-item {
  padding: 0.25rem 1.5rem !important;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.h_inherit {
  height: inherit;
}

.w-50px {
  width: 50px !important;
}

.global_red_color {
  color: #f05a28;
}

.w-120px {
  width: 120px !important;
}

.w-70px {
  width: 70px !important;
}

.h-80 {
  height: 80px;
}

.mlr-auto {
  margin: 0 auto;
}

.bg-white {
  background-color: #ffffff !important;
}

.l-lh-42 {
  height: 43px;
  line-height: 31px;
}

.form-control:focus {
  box-shadow: unset !important;
  background-color: #f5f5f5;
}

.form-control:disabled {
  background-color: #f2f2f2;
  border-color: hsl(0, 0%, 90%) !important;
  color: #999999;
}

.custom-select:focus {
  box-shadow: unset !important;
}

.global_color {
  color: #00afee !important;
}

.space-evenly {
  justify-content: space-evenly;
}

.nav-list-menu.active .nav-view-content .name {
  color: #00afee;
}

.disabled_links {
  opacity: 0.5;
}

.max-800 {
  max-width: 800px;
}

.max-900 {
  max-width: 900px;
}

.max-600 {
  max-width: 600px;
}

.w-9 {
  width: 9%;
}

.w-8 {
  width: 8%;
}

.justify-content-around {
  justify-content: space-around;
}

a:hover {
  text-decoration: none;
}

.success-div {
  background: rgba(0, 175, 238, 0.09);
}

.column-direction {
  flex-direction: column;
}

.headerSuccess {
  font-family: "Poppins";
  font-size: 14px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
}

.bodySuccess {
  text-align: justify;
  font-family: "Poppins";
  font-size: 14px !important;
  font-style: normal;
  font-weight: 300;
  line-height: 18px;
}

h4 {
  font-family: inherit;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  // line-height: 17px;
  margin: 0px;
}

div .rc-anchor-light {
  background: #ffffff !important;
}

.custom-select {
  padding: 0.375rem 0.3753rem 0.375rem 0.75rem !important;
  text-align: center;
}

.custom-select-caret {
  display: flex;
  // align-items: center;
  flex-direction: column;
}

.customInputSelect {
  background-repeat: no-repeat !important;
  background-position: right 17px top 1em, right 10px top 1em !important;
  background-size: 7px 6px, 7px 6px !important;
  border: none !important;
  background-image: linear-gradient(45deg, transparent 50%, #000000 50%),
    linear-gradient(135deg, #000000 50%, transparent 50%) !important;
}

.analt_ilter_sel_text {
  font-size: 12px;
  color: #000000;
}

.light-text {
  font-family: inherit;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  color: #b0b0b0;
}

.checkbox_color {
  accent-color: #f05a28;
}

.input-bg {
  background: #e9ecef;
  border: 1px solid #ced4da !important;
}

.border-none {
  border: none !important;
}

.react-date-picker__wrapper {
  border: 0px !important;
}

.border-radius-25rem {
  border-radius: 0.25rem;
}

.table-theme {
  word-break: break-all;

  thead th,
  tbody td {
    text-transform: capitalize;
  }

  thead tr {
    background: #495057;
  }

  tbody tr {
    &:hover {
      background-color: lighten(theme-color("primary"), 50%);
    }
  }

  thead th {
    font-size: convertToRem(14);
    vertical-align: middle;
    color: #ffffff;
    font-size: 13px;
    font-weight: 500;
    white-space: nowrap;
  }

  tbody td {
    font-size: 13px;
    letter-spacing: 0.2px;
    font-weight: 300;
    vertical-align: middle;
    color: #000000;
    font-family: inherit;
    transition: all 0.3s ease;
    white-space: nowrap;
  }

  tbody .td-role {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .arrow-down {
    margin-left: 4px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #000000;
  }

  &.theme-light-grey {
    thead {
      th {
        font-size: convertToRem(15);
        color: theme-color("lightGrey");
        font-weight: 500;
      }

      border-bottom: 1px solid theme-color("gainsboro");
    }

    tbody {
      tr {
        td {
          color: theme-color("darkGrey-1");
          font-size: convertToRem(14);
          font-weight: 400;
          font-family: inherit;
          transition: all 0.3s;
        }

        &:hover {
          cursor: pointer;
          background: lighten($color: theme-color("primary"), $amount: 48);

          td {
            font-weight: 600;
          }
        }

        &.active {
          cursor: pointer;
          background: lighten($color: theme-color("primary"), $amount: 48);

          td {
            color: theme-color("darkGrey-1");
            font-size: convertToRem(14);
            font-weight: 700;
            transition: all 0.3s;
          }
        }
      }
    }
  }

  &.with-dot {
    tbody tr {
      td:first-child {
        position: relative;
        padding-left: 2rem;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
      }

      &:not(.active) {
        td:not(.empty):first-child {
          &::before {
            content: "•";
            font-size: 1.4rem;
            position: absolute;
            left: 10%;
            bottom: 1.2rem;
            color: theme-color("primary");
          }
        }
      }
    }
  }
}

.buttonType2Disabled {
  opacity: 0.65;
  border-radius: 5px;
  background-color: #c4c4c4 !important;
  text-align: center;
  padding: 5px 10px;
  width: 100%;
  cursor: not-allowed;
  font-family: inherit;
}

.avatar {
  width: inherit;
  height: inherit;
  object-fit: cover;
  border-radius: 50%;
}

.alphatub-card {
  background: #ffffff;
  box-shadow: 1px 1px 3px #0000001d !important;
  border-radius: 10px;
}

.alphatub-card-top-spacing {
  padding: 20px 14px 5px 14px;
}

.alphatub-card-bottom-spacing {
  padding: 10px 14px 20px 14px;
}

.alphatub-card-h3 {
  font-family: inherit;
  font-style: normal;
  font-weight: 600;
  font-size: 11px !important;
}

.alphatub-card-h2 {
  font-family: inherit;
  font-style: normal;
  font-weight: 600;
  font-size: 18px !important;
  padding-bottom: 5px;
}

.alphatub-card-bold-p {
  font-family: inherit;
  font-style: normal;
  font-weight: 500;
  font-size: 11px !important;
}

.alphatub-card-normal-p {
  font-family: inherit;
  font-style: normal;
  font-weight: 400;
  font-size: 11px !important;
}

.alphatub-card-normal-link {
  font-family: inherit;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.3px;
  color: #5a8ff4 !important;
  cursor: pointer;
  font-size: 11px !important;
}

.text-end {
  text-align: end;
}

.alphatub-card-border {
  border-bottom: 0.2px solid #eeeeee;
}

.space-around {
  justify-content: space-around;
}

.center-content {
  justify-content: center;
}

.noImage {
  width: 100px;
  height: 100px;
}

.space-between {
  justify-content: space-between;
}

.small-button {
  font-family: inherit;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  text-align: center;
  color: #00afee;
  border-width: 2px;
}

.fnt-10 {
  font-size: 10px !important;
}

.custom-reselect {
  .m-0__control {
    background: #e9ecef;
    border-radius: 4px;
    border: 1px solid #ced4da !important;
    background-repeat: no-repeat;
    background-image: linear-gradient(45deg, transparent 50%, currentColor 50%),
      linear-gradient(135deg, currentColor 50%, transparent 50%);
    background-position: right 17px top 0.9em, right 10px top 0.9em;
    background-size: 7px 6px, 7px 6px;
    min-height: 31px !important;
  }

  .m-0__indicators {
    opacity: 0 !important;
  }

  .m-0__control--is-focused {
    background-color: #e9ecef !important;
    border: 1px solid #ced4da !important;
    background-repeat: no-repeat;
    background-image: linear-gradient(45deg, transparent 50%, currentColor 50%),
      linear-gradient(135deg, currentColor 50%, transparent 50%);
    background-position: right 17px top 0.9em, right 10px top 0.9em;
    background-size: 7px 6px, 7px 6px;
    border-radius: 5px;
    min-height: 31px;
  }
}

.custom-reselect
  .css-yk16xz-control
  .css-g1d714-ValueContainer
  .css-1wa3eu0-placeholder {
  top: 43% !important;
}

.custom-reselect1 .css-1hb7zxy-IndicatorsContainer {
  opacity: 1;
}

.custom-reselect1 .css-2b097c-container {
  background: #e9ecef;
  border-radius: 5px;
  border: 2px solid #00afee !important;
}

.custom-reselect1 .css-yk16xz-control {
  background: #ffffff;
  border: 0px solid #00afee !important;
  background-repeat: no-repeat;
  flex-wrap: nowrap;
  height: 31px !important;
  min-height: 31px !important;
  // background-image: linear-gradient(45deg, transparent 50%, currentColor 50%),
  // linear-gradient(135deg, currentColor 50%, transparent 50%);
  // background-position: right 17px top 1em, right 10px top 1em;
  // background-size: 7px 7px, 7px 7px;
}

.custom-reselect1 .css-1pahdxg-control {
  border-color: #ffffff !important;
  box-shadow: none;
  height: 31px !important;
  flex-wrap: nowrap;
  min-height: 31px !important;
}
.buttonTypes2 {
  // color: #00afee !important;
  border: 2px solid #00afee;
  border-radius: 5px;
  text-align: center;
  padding: 5px 10px;
  width: 100%;
  cursor: pointer;
  background: #ffffff !important;
}
.buttonTypes2.disabled {
  opacity: 1;
  background-color: #e9ecef !important;
  border: 1px solid #e9ecef !important;
  color: #ffffff !important;
}
// .buttonTypes2.disabled:hover {
//     opacity: 1;
//     background-color: #e9ecef !important;
//     border: 1px solid #e9ecef !important;
//     color: #ffffff;
// }

.buttonType1 {
  color: #00afee !important;
  border: 2px solid #00afee;
  border-radius: 5px;
  text-align: center;
  padding: 5px 10px;
  width: 100%;
  cursor: pointer;
  background: #ffffff !important;
}

.empty_data_container {
  width: 85%;
  margin: 0 auto;
}

.buttonType7 {
  color: #000000 !important;
  border: 2px solid #00afee;
  border-radius: 5px;
  text-align: center;
  padding: 5px 10px;
  width: 100%;
  cursor: pointer;
}

.buttonType7:hover {
  color: #fff !important;
  background-color: #00afee !important;
  border-color: #00afee;
}

.btn-primary:disabled {
  background-color: #e9ecef !important;
  border: 2px solid #e9ecef !important;
  color: #ffffff !important;
}

.buttonType1.disabled {
  opacity: 1;
  background-color: #e9ecef !important;
  border: 1px solid #e9ecef !important;
  color: #ffffff !important;
}

.disabledText {
  color: #e9ecef !important;
  font-family: inherit;
  font-style: normal;
  font-weight: 300;
  cursor: pointer;
  font-size: 11px !important;
}

.buttonType1.disabled:hover {
  opacity: 1;
  background-color: #e9ecef !important;
  border: 1px solid #e9ecef !important;
  color: #ffffff;
}

.buttonType1:hover {
  color: #fff !important;
  background-color: #00afee !important;
  border-color: #00afee;
}

.buttonTypes {
  color: #00afee !important;
  border: 2px solid #00afee;
  border-radius: 5px;
  text-align: center;
  width: 100%;
  cursor: pointer;
  background: #ffffff !important;
}

.buttonTypes.disabled {
  opacity: 1;
  background-color: #e9ecef !important;
  border: 1px solid #e9ecef !important;
  color: #ffffff !important;
}

.buttonTypes.disabled:hover {
  opacity: 1;
  background-color: #e9ecef !important;
  border: 1px solid #e9ecef !important;
  color: #ffffff;
}

.buttonTypes:hover {
  color: #fff !important;
  background-color: #00afee !important;
  border-color: #00afee;
}
.popupButton {
  color: #b0b0b0 !important;
  border: 1px solid #b0b0b0;
  border-radius: 5px;
  text-align: center;
  padding: 5px 10px;
  cursor: pointer;
}

.popupButton:hover {
  color: #fff !important;
  background-color: #00afee !important;
  border-color: #00afee;
}

.columnHeader {
  text-align: left;
  border: 0px !important;
}

.back-btn {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.action-btn {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.text-align-left {
  text-align: left;
}

.content-end {
  justify-content: flex-end;
}

.righttub-members {
  text-align: center;
}

// modal css

.modal-dialog {
  height: calc(100% - 1.75rem * 2);
  display: flex;
  align-items: center;
}

.modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-body {
  overflow-y: scroll;
}

.modal-footer {
  border: 0px !important;
}

.popup-header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup-close {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.popup-header-close {
  align-self: flex-end;
}

.popup-header {
  font-family: inherit;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
}

.modal-body {
  padding: 0px 1.5rem !important;
}

.form-check-input {
  position: relative !important;
  margin: 0px !important;
}

.input-radio {
  display: flex;
  flex-direction: column;
}

.optionalText {
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  color: #b0b0b0;
}

.manageseat_addseat {
  width: 80px;
  margin: 0 auto;
}

.highlight {
  background-color: #f0f0f0 !important;
  color: #000000;
}

.table-theme thead th,
.table-theme tbody td {
  text-transform: none;
}

.tickdiv {
  margin-top: 31px;
  padding: 5.5px;
  text-align: center;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background: #e9ecef;
  border-left: none !important;
  border: 1px solid #ced4da;
}

.br-rightnone {
  border-right: none !important;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.flex-property {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.username_available {
  font-family: inherit;
  font-size: 11px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  color: #118c4f;
}

.f-12 {
  font-size: 12px !important;
}

// tub sessions

.session-calendar {
  .react-calendar__tile:disabled {
    color: #000000 !important;
    background-color: #c4c4c4 !important;
  }

  .react-calendar__navigation {
    margin-bottom: 1em;
    padding: 10px 7px 5px 7px;
    height: 75px;
    display: flex;
    justify-content: space-between;
  }

  .react-calendar__navigation button {
    background-color: #ffffff !important;
    min-width: 41px !important;
    height: 44px !important;
    margin: 8px !important;
  }
}

.arrow-background {
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 14, 51, 0.05);
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.arrow {
  width: 30px;
  height: 30px;
  font-size: 32px;
  line-height: 30px;
  text-align: center;
}

.w-35px-auto {
  width: 35px;
  margin: 0 auto;
}

.tub-pad-1 {
  margin: 10px 10px 0px 10px;
}

.tub-card-1 {
  background: #f5f5f5;
  box-shadow: 0px 0.5px 3px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px 20px;
}

.tub-color-1 {
  color: #00afee;
}

.f-15 {
  font-size: 15px !important;
}

.f-14 {
  font-size: 14px !important;
}

.tub-activities {
  width: 100px;
  margin: 0 auto;
  text-align: center;
}

.tub-circle {
  border: 2px solid #00afee;
  border-radius: 50%;
  height: 75px;
  width: 75px;
  margin: 0 auto;
  cursor: pointer;
}

.tub-plus {
  color: #00afee;
  font-size: 40px;
  font-weight: 100;
  height: 75px;
  line-height: 75px;
}

.hovered-tub-circle {
  border: 2px solid #f05a28;
  border-radius: 50%;
  height: 75px;
  width: 75px;
  margin: 0 auto;
  cursor: pointer;
}

.hovered-tub-plus {
  color: #f05a28;
  font-size: 40px;
  font-weight: 100;
  height: 75px;
  line-height: 75px;
}

.tub-content-1 {
  font-family: inherit;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 500;
  color: #949494;
  line-height: 24px;
}

.tub-content-2 {
  font-family: inherit;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  color: #c4c4c4;
}

.tub-bg-type-1 {
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 100%;
  position: relative;
}

.session_upcoming_objects {
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 100%;
  padding: 20px 1rem 5px 1rem;
  position: relative;
}

.player_bg {
  background-color: #00afee;
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.player_bg p {
  text-align: center;
  line-height: 27px;
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
}

.h91 {
  height: 91%;
}

.br10 {
  border-radius: 10px;
}

.session_tub_inner {
  padding: 15px 8px 0px 8px;
}

.tub-bg-type-2 {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.addFilter {
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.25));
}

.tub-session-card {
  border-radius: 10px;
  background-color: #ffffff;
  position: relative;
}

.session-image {
  width: 170px;
}

.tub-day {
  color: #949494;
  font-family: inherit;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
}

.tub-acts .sc-ifAKCX .sc-bdVaJa .kVtMtA {
  background-color: #ffffff;
  box-shadow: none;
  left: -135px;
  height: 25px;
  width: 25px;
  z-index: 1;
  opacity: 1;
}

.tub-acts .sc-bdVaJa .sc-bwzfXH {
  background-color: #ffffff;
  box-shadow: none;
  right: -135px;
  height: 25px;
  z-index: 1;
  width: 25px;
  opacity: 1;
}

.sc-ifAKCX .sc-bdVaJa .kVtMtA {
  background-color: #ffffff;
  box-shadow: none;
  left: 0px;
  height: 25px;
  width: 25px;
  opacity: 1;
}

.sc-bdVaJa .sc-bwzfXH {
  background-color: #ffffff;
  box-shadow: none;
  right: 10px;
  height: 25px;
  z-index: 1;
  width: 25px;
  opacity: 1;
}

.search-type-1 {
  background: #f5f5f5;
  border-radius: 15px;
  border: none !important;
}

.borderBg-type-1 {
  background: #f5f5f5;
  border-radius: 5px;
  border: none !important;
}

.f-16px {
  font-size: 16px !important;
}

.bg-tub {
  background: #00afee !important;
}

.c-white {
  color: #ffffff !important;
}

.btn-bg1 {
  background-color: #f1f1f1 !important;
}

.h-24-lh-12 {
  height: 24px;
  line-height: 12px;
}

.h-lh-25 {
  height: 25px;
  line-height: 26px;
}

.h-20-lh-7 {
  height: 20px;
  line-height: 7px;
}

.iHacco {
  grid-gap: 5px !important;
}

.session-calendar {
  .react-calendar .react-calendar__viewContainer .react-calendar__tile {
    flex-basis: 12.2857% !important;
    max-width: 12.2857% !important;
    padding: 0.75em 0.1em !important;
    margin: 6px 1.6px !important;
    background: #ffffff;
    border-radius: 5px !important;
  }
}

.w-100px {
  width: 100px;
}

.react-calendar__month-view__days__day--weekend .highlight {
  color: #00afee !important;
}

.react-calendar__month-view__days__day--weekend {
  color: #000000 !important;
}

.react-calendar__tile:disabled {
  color: #757575 !important;
}

// .tub-acts-session .sc-dnqmqq  {
//     width: 180px;
// }

.tub-session-acts-user-icon {
  width: 30px;
}

// .tub-acts-session .sc-ifAKCX {
//     width: 98%;
// }
// .tub-acts-session .sc-EHOje {
//     display: flex;
//     align-items: center;
//     justify-content: center;
// }

.tab-dropdown .tab-dropdown-btn {
  background-color: #ffffff !important;
  text-align: center;
  color: #000000;
  width: 100%;
  padding: 0;
  line-height: 39px;
  font-size: 15px;
}

.tab-dropdown .tab-dropdown-btn:hover {
  border-color: #e9ecef #e9ecef #dee2e6;
  color: #000000;
}

.tab-dropdown-btn-active {
  background-color: #ffffff;
  border-bottom: 4px solid #00afee;
  border-top-color: #ffffff;
  border-left-color: #ffffff;
  border-right-color: #ffffff;
  text-align: center;
  color: #00afee !important;
  font-weight: 500;
}

.tab-dropdown-menu {
  width: 100% !important;
  text-align: center;
}

.dropdown-item {
  color: #000000;
  font-weight: 500;
  font-family: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown-item:hover {
  background-color: #ffffff;
  color: #00afee;
}

.dp-spanText {
  color: #949494;
  margin-left: 3px;
}

// .inbox-inner-content {
//     height: 450px;
// }
.inheritHeight {
  height: inherit;
}

.tub-content-3 {
  color: #5071a3;
  font-style: normal;
  font-weight: 500;
  font-family: inherit;
}

.tub-content-4 {
  color: #000000;
  font-style: normal;
  font-weight: 500;
  font-family: inherit;
}

.f-18 {
  font-size: 18px !important;
}

.wh-75 {
  width: 75px !important;
  height: 75px;
}

.w-180 {
  width: 180px;
  margin: 0 auto;
}

.buttonType3 {
  color: #000000;
  border: 2px solid #00afee;
  border-radius: 10px;
  text-align: center;
  padding: 5px 10px;
  width: 100%;
  background: #ffffff;
}

.buttonType3:hover {
  background-color: #00afee !important;
  color: #ffffff;
}

.studentBorder {
  border: 1px solid #00afee;
}

.buttonType4 {
  color: #000000;
  border: 2px solid #f05a28;
  border-radius: 10px;
  text-align: center;
  padding: 5px 10px;
  width: 100%;
  background: #ffffff;
}

.buttonType6 {
  color: #ffffff;
  border: 2px solid #f05a28;
  border-radius: 10px;
  text-align: center;
  padding: 5px 10px;
  width: 100%;
  background: #f05a28;
}

.buttonType4:hover {
  background-color: #f05a28 !important;
  color: #ffffff;
}

.w-150px {
  width: 150px;
}

.w-183px {
  width: 183px;
}

.margin-lr-auto {
  margin: 0 auto;
}

.inner-content-spnImg {
  position: absolute;
  top: -28px;
  left: 8px;
}

.tubtypes_student {
  position: absolute;
  top: -28px;
  left: 8px;
}

.p-04rem {
  padding: 0.4rem;
}

.pt-50 {
  padding-top: 50px;
}

.pt-30 {
  padding-top: 30px;
}

.dropdown-toggle::after {
  display: none;
}

.table-sm td {
  padding: 0.8rem;
}

.w-50px {
  width: 50px;
}

h5 {
  margin: 0;
}

.py-12 {
  padding: 12px 0px;
}

.top-bottom-borders {
  border-top: 2px #000000 solid;
  border-bottom: 2px #000000 solid;
}

.dropdown-menu {
  left: -50px;
  top: 90px;
  width: 150px;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  border: 1px solid #f1f1f1;
}

.nav-class-dropdown:after {
  content: "";
  width: 0px;
  height: 0px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 22px solid #f1f1f1;
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  left: -11px;
  right: -85px;
  top: -23px;
  margin: 0 auto;
}

.class_tooltip .tooltiptext {
  visibility: hidden;
  width: 68px;
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  color: #000000;
  text-align: center;
  font-family: inherit;
  font-size: 14px;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  right: -45px;
  top: -42px;
}

.class_tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.class_tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 98%;
  left: 25%;
  margin-left: -5px;
  /* box-shadow: 0px 0px 0px rgb(0 0 0 / 25%); */
  border-width: 5px;
  border-right: 10px solid rgba(0, 0, 0, 0.25);
  border-top: 9px solid rgba(0, 0, 0, 0.25);
  border-left: 1px solid rgba(0, 0, 0, 0.25);
  border-style: solid;
  border-color: #ffffff transparent transparent transparent;
}

.position_relative {
  position: relative;
}

.notification_count {
  position: absolute;
  top: -13px;
  background: #f05a28;
  right: -12px;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 50%;
  font-size: 11px;
  line-height: 20px;
  font-weight: 600;
}

.h-100px {
  height: 100px;
}

.h-400px {
  height: 400px;
}

// class presentation
.explore_stencil_image {
  border: 1px solid #717171;
  border-radius: 0px;
}

.explore_stencil_container {
  position: absolute;
  top: 0;
}

.explore_reading_pr_stencil_container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.min-h-100vh {
  min-height: 100vh;
}

.clp-col {
  margin: 80px 0px 0px 0px;
}

.clp-bg {
  background-color: #717171;
}

.nav-class-title {
  flex: 7 1;
  justify-content: center;
}

.clp-class-name {
  font-family: inherit;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  color: #c4c4c4;
}

.clp-tub-day {
  color: #e2e2e2;
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
}

.game-student-names {
  color: #b0b0b0;
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
}

.selected-game-student-names {
  color: #ffffff;
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
}

.selected-clp-tub-day {
  color: #00afee !important;
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
}

.clp-threedots {
  width: 22px;
  height: 25px;
  background-image: radial-gradient(circle, #ffffff 2px, transparent 3px);
  background-size: 100% 33.33%;
}

.plr-35 {
  padding: 0px 35px;
}

.clp-heading-1 {
  font-family: inherit;
  font-size: 36px !important;
  color: #ffffff;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
  line-height: 40px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-60 {
  margin-top: 60px;
}

.h-90 {
  height: 90%;
}

.buttonType2 {
  color: #f05a28 !important;
  border-radius: 5px;
  border: 2px solid #ffffff;
  text-align: center;
  padding: 5px 10px;
  width: 100%;
  cursor: pointer;
  background: #ffffff !important;
  font-family: inherit;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}

.buttonType2:hover {
  color: #00afee !important;
  border: 2px solid #00afee;
  font-family: inherit;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}

.fixed-content {
  position: fixed;
  bottom: 10px;
}

.w-40 {
  width: 40px;
  height: 40px;
  border-radius: 10px;
}

.w-40-brHalf {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.w-50 {
  width: 50%;
}

.w-90 {
  width: 90%;
}

.color-white {
  color: #ffffff;
}

.clp-fulltext {
  color: #ffffff;
  font-family: inherit;
  font-size: 30px !important;
  font-style: normal;
  font-weight: 700;
  text-align: center;
}

.clp-white-br {
  border-top: 2px solid #ffffff;
}

.clp-tags {
  color: #ffffff;
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
}

.w-80 {
  width: 80%;
}

.mlr-auto {
  margin: 0 auto;
}

.highlighted-singleText {
  color: #00afee;
}

.text_white {
  color: #ffffff;
}

.carousel .thumbs-wrapper {
  width: 100%;
}

.carousel .thumbs-wrapper {
  position: fixed !important;
  width: 100% !important;
  bottom: 10px !important;
  margin: 0 !important;
  left: 50%;
  transform: translateX(-50%) !important;
}

.carousel .thumbs-wrapper ul {
  width: 95%;
  margin: 0 auto;
}

.text-right {
  text-align: right;
}

.carousel .control-prev.control-arrow {
  height: 85px !important;
  vertical-align: middle;
  background: #717171;
  opacity: 1 !important;
  margin-top: -49px;
}

.carousel .control-next.control-arrow {
  top: 44% !important;
}

.carousel .thumbs {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.carousel .thumb {
  border: none !important;
  padding: 0 !important;
  margin-right: 0 !important;
  width: 60px !important;
}

.carousel .thumb.selected > div > div > div > p {
  color: #00afee !important;
}

.w-6 {
  width: 6%;
}

.clp-gameMode {
  font-family: inherit;
  font-size: 32px !important;
  font-style: normal;
  font-weight: 700;
  color: #ffffff;
  display: flex;
  padding: 0px 50px;
  line-height: 40px;
  align-items: center;
  justify-content: center;
  height: inherit;
}

.clp-game-head {
  font-family: inherit;
  font-size: 50px !important;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.25em;
  color: #ffffff;
}

.clp_heading1 {
  font-family: inherit;
  font-size: 40px !important;
  font-style: normal;
  font-weight: 700;
  color: #ffffff;
}

.margin-lr-auto {
  margin: 0 auto;
}

.game_player_img:hover img {
  border: 2px solid #00afee;
  border-radius: 50%;
}

.selected_class_att_player {
  border: 2px solid #f05a28 !important;
}

.game_player_img img {
  border: 2px solid transparent;
  border-radius: 50%;
  height: inherit;
  width: inherit;
  object-fit: cover;
}

.game_player_img:hover .clpSelectPlayer {
  color: #00afee;
}

.mtb-50 {
  margin: 50px auto;
}

.jumbotron {
  background-color: #717171;
}

.bg-playmode {
  background-color: #00afee !important;
}

.h-250px {
  height: 250px;
}

.h-300px {
  height: 300px;
}

.lh-250 {
  line-height: 250px;
}

.w-70 {
  width: 70px;
}

.whbr-75 {
  width: 75px !important;
  height: 75px;
  object-fit: cover;
  border-radius: 50%;
}

.whbr-70 {
  width: 70px !important;
  height: 70px !important;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid transparent;
}

.w-85 {
  width: 85px;
}

.clpSelectPlayer {
  height: 50px;
  color: #e2e2e2;
  font-family: inherit;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
}

.clpSelectedPlayer {
  color: #e2e2e2;
  font-family: inherit;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
}

.min-h-100vh {
  min-height: 100vh;
}

.selectedPlayer {
  position: absolute;
  top: 1px;
  right: 9px;
  width: 25px;
}

@media (min-width: 1200px) {
  .clpContainer {
    max-width: 103.25rem !important;
  }
}

.fixedGameButtons {
  position: fixed;
  bottom: 40px;
  right: 45px;
}

.group_numbers {
  border: 3px solid #00afee;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  line-height: 37px;
  font-weight: 700;
  font-family: inherit;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}

.group_numbers-active {
  background-color: #00afee;
}

.darkLayer {
  position: absolute;
  width: 100%;
  background-color: #000000;
  opacity: 0.5;
}

.hidden-grps {
  opacity: 0.5;
}

.w-75px {
  width: 75px;
}

.group-box {
  border: 2px solid #00afee;
  border-radius: 10px;
}

.selected-group-box {
  border: 3px solid #00afee;
  border-radius: 10px;
}

.unselected-group-box {
  border: 2px solid #b0b0b0;
  border-radius: 10px;
}

.game-options {
  width: 300px;
  margin: 0 auto;
}

.selected-ind-student-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  zoom: 1.3;
  border: 1.5px solid #00afee;
  object-fit: cover;
}

.pos-fixed {
  position: fixed;
}

.highlight-ind-student {
  position: relative;
  bottom: 12px;
  text-align: center;
}

.mo-ind-wr-answer {
  opacity: 0.2;
}

.wrong-answer-img {
  width: 30px;
  height: 30px;
}

.wrong-answer-pos {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.selectedStudentsFixed {
  position: fixed;
  bottom: 10px;
}

.mis-letter-option {
  font-weight: 700;
  font-family: inherit;
  color: #ffffff;
  word-break: break-all;
}

.mlr-25 {
  margin: 0px 25px;
}

.miss-letter-heading {
  font-weight: 400;
  font-family: inherit;
  color: #ffffff;
  font-size: 40px !important;
}

.mis-letter-word {
  font-weight: 700;
  font-family: inherit;
  color: #ffffff;
  font-size: 70px !important;
  margin: 0px 3px;
}

.blank_word {
  margin: 0px 10px;
  background-color: #ffffff;
  width: 35px;
  height: 10px;
  position: relative;
  bottom: -53px;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.correct-answer-mis-letter {
  color: #00afee;
}

.wrong-answer-mis-letter {
  color: #f05a28;
}

.reject-card-p1 {
  font-weight: 400;
  font-family: inherit;
  color: #a8a6a6;
  font-size: 16px !important;
}

.pendingAlphabets {
  justify-content: center;
  align-items: center;
  gap: 3px 20px;
}

.rejectAlphabet {
  font-weight: 600;
  cursor: pointer;
  font-family: inherit;
  color: #000000;
  font-size: 20px;
}

.buttonType5 {
  color: #00afee;
  border: 1px solid #00afee;
  border-radius: 5px;
  text-align: center;
  height: 40px;
  width: 100%;
  cursor: pointer;
  background: #ffffff;
}

.buttonType5:hover {
  border: 2px solid #00afee;
  height: 40px;
}

.explore-alphabet {
  font-weight: 700;
  font-family: inherit;
  color: #ffffff;
  font-size: 80px !important;
  line-height: 120px;
}

.gap-25 {
  gap: 25px 0px;
}

.type2Image {
  border-radius: 5px;
  width: inherit;
  height: inherit;
}

.type2ActivityImages {
  height: 200px;
  width: 100%;
  position: relative;
}

.justify-between {
  justify-content: space-between;
}

.inbox-pending-stencilImage {
  width: inherit;
  height: inherit;
}

.student_fname {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  font-family: inherit;
}

.notApproved {
  color: #f05a28;
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  font-family: inherit;
}

.flex {
  display: flex;
  align-items: center;
}

.custom_class_table {
  table {
    table-layout: fixed;
    word-break: break-all;

    thead tr {
      background: #495057;

      th {
        vertical-align: middle;
        color: #ffffff;
        font-size: 13px;
        font-weight: 500;
      }

      th:nth-child(1) {
        width: 8%;
      }

      th:nth-child(2) {
        width: 20%;
      }

      th:nth-child(3) {
        width: 16%;
      }

      th:nth-child(4) {
        width: 18%;
      }

      th:nth-child(5) {
        width: 25%;
      }

      th:nth-child(6) {
        width: 13%;
      }
    }
  }
}

.class_table_teachers {
  display: flex;
  align-items: center;

  .extra_teachers {
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
  }

  .class_teachers_tooltip {
    visibility: hidden;
    max-width: 110px;
    width: 100%;
    background: #ffffff;
    border: 1px solid rgba(133, 125, 125, 0.25);
    color: #000000;
    text-align: left;
    font-family: inherit;
    font-size: 10px;
    border-radius: 6px;
    padding: 5px 10px;
    position: absolute;
    z-index: 1;
    transition: opacity 0.3s;
    right: -30px;
    top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .extra_teachers:hover .class_teachers_tooltip {
    visibility: visible;
  }
}

.student-table {
  table {
    thead {
      tr {
        background: #495057;

        th {
          vertical-align: middle;
          color: #ffffff;
          font-size: 13px;
          font-weight: 500;
        }
      }
    }
  }
}

// .student-table table thead tr th:first-child {
//     width: 12%;
// }

// .student-table table thead tr th:nth-child(2) {
//     width: 30%;
// }

// .student-table table thead tr th:nth-child(3) {
//     width: 25%;
// }

// .student-table table thead tr th:nth-child(4) {
//     width: 28%;
// }

.manageSeatsTable {
  table {
    thead {
      tr {
        background: #495057;

        th {
          vertical-align: middle;
          color: #ffffff;
          font-size: 13px;
          font-weight: 500;
        }
      }
    }
  }
}

.resendRequest {
  color: #00afee;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  font-family: inherit;
  height: 19px;
  line-height: 21px;
  cursor: pointer;
}

.w-105px {
  width: 105px;
}

.studentEditIcon {
  width: 20px;
  height: 20px;
}

.invoice_table thead tr th:first-child {
  width: 15%;
}

.invoice_table table thead tr th:nth-child(2) {
  width: 25%;
}

.invoice_table table thead tr th:nth-child(3) {
  width: 20%;
}

.invoice_table table thead tr th:nth-child(4) {
  width: 20%;
}

.invoice_table table thead tr th:nth-child(5) {
  width: 20%;
}

.invoice_content {
  font-family: inherit;
  font-size: 14px;
  font-weight: 300;
  color: #b0b0b0;
}

.countsOfTypeTwo {
  position: absolute;
  height: 25px;
  z-index: 2;
  width: 25px;
  top: 50%;
  text-align: center;
  background: rgba(255, 255, 255, 0.67);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

.mysession_count {
  right: -3px;
}

.createsession_count {
  right: 5px;
}

.countypeTwoObjects {
  line-height: 27px;
  font-size: 12px;
  font-weight: 500;
}

.selected_game_players {
  position: fixed;
  bottom: 10px;
}

.h-20 {
  height: 20px;
}

.mlr-auto {
  margin: 0 auto;
}

.rightScrollBar {
  overflow-y: auto;
  overflow-x: hidden;
  // min-height: 30%;
  // max-height: 42%;
}

.pt10 {
  padding-top: 10px !important;
}

.h93_9 {
  height: 93.9%;
}

.br-50 {
  border-radius: 50%;
}

.pt-45 {
  padding-top: 45px;
}

.profile_lt_label {
  font-size: 11px;
  font-family: inherit;
  font-weight: 300;
}

.profile_lt_value {
  font-size: 11px;
  font-family: inherit;
  font-weight: 400;
}

.w-6 {
  width: 6% !important;
}

.whbr-40 {
  width: 40px !important;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
}

.table-theme tbody tr:nth-child(even) {
  background: #f1f1f1;
}

.bg_transparent {
  background: transparent;
}

.student_profile_name {
  font-size: 18px !important;
  font-family: inherit;
  font-weight: 600;
}

.inc_letter_size {
  font-size: 36px !important;
}

.suggestion_container {
  z-index: 1;
  position: absolute;
  top: 32px;
  padding: 10px;
  margin-top: 10px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 10px;
  background: #ffffff;
  width: 91%;
  height: 150px;
  overflow: auto;
  border-radius: 10px;
}

.suggestion {
  font-size: 13px !important;
  font-family: inherit;
  font-weight: 500;
  cursor: pointer;
}

.suggestion:hover {
  color: #00afee;
}

.no_suggestion {
  font-size: 13px !important;
  font-family: inherit;
  font-weight: 500;
  text-align: center;
  line-height: 117px;
}

.game_player {
  width: 75px;
  height: 90px;
}

.tabnavs {
  position: sticky;
  top: 0;
}

.ov_l_1 {
  padding: 8px;
  border-radius: 5px;
  background-color: #ffffff;
}

.ov_p_1 {
  font-size: 14px !important;
  font-family: inherit;
  font-weight: 600;
}

.ov_p_2 {
  font-size: 12px !important;
  font-family: inherit;
  font-weight: 400;
}

.ov_l_meter {
  background-color: #ffffff;
  padding: 10px 20px 20px 20px;
  border-radius: 5px;
  height: 125px;
  vertical-align: middle;
  display: table-cell;
}

.ov_l_meter_head {
  font-size: 14px !important;
  font-family: inherit;
  font-weight: 500;
}

.ovl_meter_count {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 22px !important;
  font-family: inherit;
  font-weight: 300;
}

.ov_l_txt2 {
  font-size: 20px !important;
  font-family: inherit;
  font-weight: 600;
  color: #0255cc;
}

.gauge_meter_3 {
  color: #008037;
}

.gauge_meter_2 {
  color: #f05a28;
}

.gauge_meter_1 {
  color: #00afee;
}

.report {
  background: #ffffff;
  padding: 5px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin-bottom: 20px;

  span {
    width: 100%;
    // margin-bottom: 25px;
  }
}

.report_heading {
  background: #ffffff;
  border-top-left-radius: 5px;
  // padding: 0px 10px;
  border-top-right-radius: 5px;
}

.report_heading p {
  font-size: 20px !important;
  font-family: inherit;
  font-weight: 500;
}

.custom_description {
  text-align: right;
  margin-right: 15px;
}

.report_gp_1_total_count {
  font-size: 13px;
  font-weight: 600;
  font-family: inherit;
}

.main_graph {
  padding-top: 10px;
}

.chart {
  margin: 0 auto;
}

.report_type {
  padding: 12px 18px;
}

.report_type p {
  font-weight: 500;
  font-family: inherit;
  font-size: 20px !important;
}

.report_type h6 {
  font-weight: 400;
  font-family: inherit;
  font-size: 16px;
}

.graph_filters_main {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #e5e5e5;
  padding: 10px 0px 12px 0px;
}

.analytics_filter {
  background: #ffffff;
  border-radius: 5px;
  border: none !important;
}

.analytics_bg {
  background-color: #ffffff;
}

.analytics_content_list {
  background-color: #ffffff;
  height: 100%;
}

.selected_date_range_filter {
  font-size: 11px;
  text-align: center;
  padding-top: 5px;
  color: #00000085;
}

.analytics_table {
  overflow-y: scroll;
  max-height: 250px;

  .table-striped tbody tr {
    background-color: #fff !important;
  }

  table {
    margin: 0;

    thead tr {
      background-color: #495057;

      th {
        padding: 0.5rem !important;
        font-weight: 500;
        font-size: 13px !important;
        font-family: inherit;
        color: #ffffff;
      }

      th:nth-child(1) {
        text-align: center;
      }

      th:nth-child(3) {
        text-align: center;
      }
    }

    tbody {
      .custom-row-class td {
        border: 1px solid #e9eaea !important;
        font-weight: 500;
        font-size: 13px !important;
        vertical-align: middle;
      }

      td:nth-child(1) {
        text-align: center;
      }

      td:nth-child(3) {
        text-align: center;
      }
    }
  }
}

.analytics_table::-webkit-scrollbar-thumb {
  background-color: #495057 !important;
}

.analytics_table::-webkit-scrollbar {
  width: 0.2rem;
  height: 0.2rem;
}

.analytics_table .table th {
  padding: 1rem !important;
  font-weight: 600;
  font-size: 13px !important;
  font-family: inherit;
}

.analytics_table .custom-row-class td {
  border: 1px solid #e9eaea !important;
  font-weight: 500;
  font-size: 13px !important;
  vertical-align: middle;
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #ffffff;
}

.analytics_ovl_cl_main {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  padding: 10px 0px;
  display: flex;
  align-items: center;
  position: sticky;
  top: 122px;
  z-index: 3;
}

.analytics_ovl_head {
  font-family: inherit;
  font-weight: 600;
  font-size: 24px !important;
}

.curriculum_progress {
  width: 100%;
  height: 30px;
  border-radius: 5px;
}

.first_half {
  background-color: #ed7d2b;
  height: 100%;
  border-top-left-radius: 0px!important;
  border-bottom-left-radius: 0px!important;

  p {
    text-align: center;
    color: #000000;
    font-size: 12px;
    font-weight: 600;
    line-height: 30px;
  }
}


.second_half {
  background-color: #00adef;
  height: 100%;
  border-top-left-radius: 0px!important;
  border-bottom-left-radius: 0px!important;
  border-top-right-radius: 0px!important;
  border-bottom-right-radius: 0px!important;
  p {
    text-align: center;
    color: #000000;
    font-size: 12px;
    font-weight: 600;
    line-height: 30px;
  }

}

.y_axis_label {
  font-size: 12px;
  font-weight: 500;
  font-family: inherit !important;
}

.x_axis_label {
  font-size: 13px;
  font-weight: 500;
  font-family: inherit !important;
}

.x_axis_content {
  font-size: 12px;
  font-weight: 400;
  font-family: inherit !important;
}

.second_half {
  background-color: #00adef;
  height: 100%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.report_super_txt {
  font-size: 12px;
  margin-right: 5px;
}

.sr_ir_main {
  background-color: #ffffff;
  padding: 15px;
  height: auto;
}

.SR_TX_1 {
  font-family: inherit;
  font-weight: 500;
  font-size: 27px !important;
}

.sr_students_list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  width: 95%;
  gap: 15px;
  margin: 0 auto;
}

.st_rp_bg_1 {
  background: url("../images/st_rp_1.svg");
  height: 220px;
  width: 100%;
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
}

.rp_top_container {
  padding: 10px 20px;
  width: 100%;
}

.justify-content-center {
  justify-content: center;
}

.rp_inner_cont {
  display: flex;
  height: 150px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.rp_right_cont {
  display: flex;
  height: 150px;
  padding: 0px 30px;
  flex-direction: column;
  justify-content: center;
  text-align: justify;
  align-items: flex-end;

  // .st_rp_txt2::after {
  //     content: ' : ';
  // }
}

.rp_img_cont {
  padding: 15px 0px;
  display: flex;
  justify-content: center;
}

.st_rp_txt2 {
  color: #ffffff;
  font-family: inherit;
  font-weight: 600;
  font-size: 18px !important;
}

.st_rp_txt3 {
  color: #ffffff;
  font-family: inherit;
  font-weight: 600;
  font-size: 18px !important;
}

.st_rp_txt1 {
  color: #ffffff;
  font-family: inherit;
  font-weight: 600;
  font-size: 48px !important;
}

.st_rp_chart_cont {
  // width: 450px;
  // padding: 5px;
  background: #ffffff;
  // filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.25));
  border-radius: 10px;
}

.st_rp_right_chart_cont {
  padding: 5px;
  background: #ffffff;
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.25));
  border-radius: 2px;
}

.st_rp_gauge {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.student_report_table {
  overflow-y: scroll;
  // height: 600px;
  border: 1px solid #e9eaea !important;
}

.student_report_table .table th {
  border-right: 1px solid #e9eaea !important;
  border-bottom: 1px solid #e9eaea !important;
  padding: 1rem !important;
  background-color: #f4f8f9;
  font-weight: 600;
  font-size: 13px !important;
  font-family: inherit;
}

.student_report_table .custom-row-class td {
  border: 1px solid #e9eaea !important;
  font-weight: 500;
  font-size: 13px !important;
  vertical-align: middle;
}

.st_rp_footer {
  border-top: 3px solid #00afee;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.main_speedometer div {
  height: 340px;
}

.speedometer {
  height: inherit !important;
}

.column-gap-50 {
  column-gap: 50px;
}

.ovl_performance_stages {
  width: 94%;
  display: flex;
  margin: 0 auto;
  flex: 12;
  padding-bottom: 10px;

  div {
    flex: 3;
    text-align: center;
    font-size: 13px;
    padding: 1px;
  }
}

.pointer::after {
  content: "80";
  display: block;
  width: 20px;
}

.session-tub-type {
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 6px 0px;
  margin: 0 auto;
  position: relative;
}

.clp-gameTypes {
  font-family: inherit;
  line-height: 33px;
  font-style: normal;
  font-weight: 700;
  color: #ffffff;
}

@media screen and (min-width: 1300px) {
  .clp-gameTypes {
    font-size: 28px !important;
  }
}

@media screen and (min-width: 991px) {
  .clp-gameTypes {
    font-size: 18px !important;
  }
}

@media screen and (min-width: 1024px) {
  .clp-gameTypes {
    font-size: 21px !important;
  }

  .optionItem1 {
    height: 250px;
    margin: 0 auto;
  }

  .mis-letter-word {
    font-size: 50px !important;
  }

  .blank_word {
    width: 30px;
    height: 7px;
  }
}

@media screen and (min-width: 1200px) {
  .clp-gameTypes {
    font-size: 24px !important;
  }
}

@media screen and (min-width: 1300px) {
  .clp-gameTypes {
    font-size: 25px !important;
  }
}

@media screen and (min-width: 1400px) {
  .clp-gameTypes {
    font-size: 26px !important;
  }

  .optionItem {
    width: 350px;
    height: auto;
    margin: 0 auto;
  }

  .optionItem1 {
    // width: 300px;
    height: auto;
    margin: 0 auto;
  }

  .mis-letter-word {
    font-size: 60px !important;
  }

  .blank_word {
    width: 30px;
    height: 10px;
  }

  .mis-letter-option {
    font-size: 50px !important;
  }
}

@media screen and (min-width: 1500px) {
  .clp-gameTypes {
    font-size: 28px !important;
  }
}

.explore_alp_container {
  width: 140px;
  height: 140px;
  background: #ffffff;
}

.explore_alp_bg {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin: 10px auto;
  background: #717171;
}

.approved_inbox_content {
  position: absolute;
  z-index: 1;
  top: 5px;
  right: 10px;
}

.approved_inbox_content img {
  width: 25px;
  height: 25px;
}

.inbox_image_popup .modal-content {
  border: none !important;
  background: transparent;
}

.inbox_image_popup .modal-body {
  overflow: hidden;
}

.tubInbox_pp_tg {
  text-align: left;
  font-family: inherit;
  font-size: 12px !important;
  font-weight: 500;
  color: #ffffff;
}

.tubInbox_pp_nm {
  font-family: inherit;
  font-size: 18px !important;
  font-weight: 500;
  text-align: left;
  color: #ffffff;
}

.tubInbox_pp_ic {
  width: 30px !important;
  height: 14px;
}

.cp_object_main {
  height: 210px;
}

.h-100 {
  height: 100%;
}

.activity_suggestion {
  padding: 0 10px;
  margin-top: 5px;
  border-radius: 10px;
  background-color: #00afee;
  margin-right: 5px;
  cursor: pointer;
}

.activity_suggestion p {
  font-size: 12px;
  font-family: inherit;
  color: #ffffff;
}

.react-date-picker__calendar {
  bottom: 0% !important;
}

.fs-10 {
  font-size: 10px !important;
}

.pt-12 {
  padding-top: 12px;
}

.cl_object_type {
  padding-bottom: 0.2rem;
}

.explore_imageTitle {
  color: #ffffff;
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
}

.home_type2_container {
  height: 93.5%;
}
.clp_content_obj {
  height: 99.7%;
}

.home_type2_inner {
  height: 92%;
}

.disabled_student {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 400 !important;
}

.h_100 {
  height: 100%;
}

.recent_approve_object {
  font-size: 13px !important;
  font-weight: 500;
  font-family: inherit;
  text-align: center;
}

.collasible-nav-dropdown .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.4em solid;
  border-right: 0.4em solid transparent;
  border-bottom: 0;
  border-left: 0.4em solid transparent;
}

.collasible-nav-dropdown .dropdown-toggle {
  padding: 0px !important;
  display: flex;
  align-items: center;
}

.collasible-nav-dropdown .dropdown-menu {
  left: -175px;
  top: 25px;
  width: auto !important;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  border: 1px solid #f1f1f1;
}

.old_gallery_image {
  width: 170px;
  height: 150px;
  background-size: cover;
  border-radius: 10px;
}

.old_gallery_image_container {
  width: 170px;
  margin: 12px;
}

.gallery_name_style {
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  text-align: center;
}

.close_input_select {
  position: absolute;
  right: 27px;
  top: 7px;
  cursor: pointer;
}

.inbox_pending_right_container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 96%;
}

.tub_inbox_discard {
  position: absolute;
  bottom: 15px;
  right: 4px;
  z-index: 3;
}

.profile_image_container {
  width: 150px;
  height: 150px;
}

.clp_home_count {
  right: -24.5px;
}

.game_option_arrow {
  top: 58% !important;
}

.tub_mix_save_btn_disabled {
  padding: 5px 10px !important;
  opacity: 1;
  background-color: #ffffff !important;
  border: 2px solid #bec0c2 !important;
  color: #000000 !important;
  border-radius: 5px;
  text-align: center;
}

.buttonType3disabled {
  opacity: 1;
  background-color: #ffffff !important;
  border: 2px solid #bec0c2 !important;
  color: #000000 !important;
  border-radius: 10px;
  padding: 8px 10px;
  text-align: center;
}

.ButtonType3 {
  color: #000000;
  border: 2px solid #00afee;
  border-radius: 10px;
  text-align: center;
  padding: 5px 10px;
  width: 100%;
  background: #ffffff;
}

.ButtonType3:hover {
  color: #fff !important;
  background-color: #00afee !important;
  border-color: #00afee;
}

.approve_tooltip {
  visibility: hidden;
  width: 250px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  color: #000000;
  text-align: center;
  font-family: inherit;
  font-size: 10px;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  transition: opacity 0.3s;
  right: -12px;
  top: -64px;
}

.approve_disabled:hover .approve_tooltip {
  visibility: visible;
  opacity: 1;
}

.approve_disabled .approve_tooltip:before,
.approve_disabled .approve_tooltip:after {
  content: "";
  border-style: solid;
  border-width: 10px;
  width: 0;
  height: 0;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.approve_disabled .approve_tooltip:before {
  border-color: #bec0c2 transparent transparent;
}

.approve_disabled .approve_tooltip:after {
  margin-top: -1.3px;
  border-color: #ffffff transparent transparent;
}

.reject_popup_slider .carousel .slider-wrapper {
  width: 85% !important;
}

.image_crop_popup .modal-body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.w_130px {
  width: 130px;
}

.expand_clp {
  width: 25px;
  height: 25px;
}

.react_daterange_picker {
  display: flex !important;
}

.DateRangePicker {
  box-shadow: 0 0 2px #d1d1d1 !important;
}

.custom-select-filter .css-1okebmr-indicatorSeparator {
  opacity: 0 !important;
  visibility: hidden;
}

// .custom-select-filter .form-group__control.css-yk16xz-control {
//   background-repeat: no-repeat;
//   background-image: linear-gradient(45deg, transparent 50%, currentColor 50%),
//     linear-gradient(135deg, currentColor 50%, transparent 50%);
//   background-position: right 17px top 1em, right 10px top 1em;
//   background-size: 7px 7px, 7px 7px;
// }
.custom-select-filter .css-2b097c-container {
  background: #f5f5f5 !important;
  border-radius: 5px !important;
  border: none !important;
}

.custom-select-filter {
  .m-0__control {
    border-color: #f5f5f5 !important;
    background-color: #f5f5f5 !important;
    background-repeat: no-repeat;
    background-image: linear-gradient(45deg, transparent 50%, currentColor 50%),
      linear-gradient(135deg, currentColor 50%, transparent 50%);
    background-position: right 17px top 0.9em, right 10px top 0.9em;
    background-size: 7px 6px, 7px 6px;
    border-radius: 5px;
    min-height: 30px;

    .m-0__value-container {
      padding: 0px 8px;

      .m-0__single-value {
        font-size: 12px;
        color: #000000;
      }

      .m-0__placeholder {
        color: #000000;
        font-size: 12px;
      }
    }

    .m-0__indicators {
      .m-0__clear-indicator {
        padding: 0px 8px;
      }

      .m-0__dropdown-indicator {
        opacity: 0 !important;
        visibility: hidden;
        padding: 0px 8px;
      }
    }
  }

  .m-0__control--is-focused {
    border-color: #f5f5f5 !important;
    background-color: #f5f5f5 !important;
    background-repeat: no-repeat;
    background-image: linear-gradient(45deg, transparent 50%, currentColor 50%),
      linear-gradient(135deg, currentColor 50%, transparent 50%);
    background-position: right 17px top 0.9em, right 10px top 0.9em;
    background-size: 7px 6px, 7px 6px;
    border-radius: 5px;
    min-height: 30px;
  }
}

// .custom-select-filter .css-yk16xz-control .css-tlfecz-indicatorContainer {
//     color: #000000

// }
// .custom-select-filter   .css-yk16xz-control .m-0__value-container--has-value .css-tlfecz-indicatorContainer{
//     opacity: 0;
// }

.custom-select-filter .css-1pahdxg-control {
  border-color: #f5f5f5 !important;
  background-color: #f5f5f5 !important;
  box-shadow: none;
}

.py-2-half {
  padding: 0.7rem 0 0.7rem 0;
}

.filterBtn {
  height: calc(1.8em + 0.75rem + 2px);
  padding: 0.575rem 0.75rem;
  font-weight: 500;
}

.selected_filter {
  color: #ffffff;
  background-color: #00afee;
  border-radius: 5px;
}

.game_mode_container {
  height: 301px;
  background-color: #00afee !important;
  cursor: pointer;
  border-radius: 10px;
}

.fixed_custom_collection {
  position: fixed;
  height: 200px;
  bottom: 0;
  padding: 15px;
  z-index: 5;
  background-color: #ffffff;
  box-shadow: -1px -6px 10px #0000001d;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.selected_custom_collection_index {
  text-align: center;
  font-size: 12px;
  color: #00afee;
  font-weight: 400;
  font-family: inherit;
}

.selected_custom_collection_img_container {
  width: 105px;
  height: 86px;
}

.selected_custom_collection_container {
  display: flex;
  overflow-x: auto;
  padding: 10px 0px;
}

.selected_custom_collection_container .inner_container {
  padding: 0px 15px;
}

// class presentation header
.clp_header_dropdown .dropdown .btn {
  background-color: #717171 !important;
}

.clp_header_dropdown .dropdown .btn:hover,
.clp_header_dropdown .dropdown .btn:active,
.clp_header_dropdown .dropdown .btn:focus {
  border-color: #717171 !important;
  box-shadow: none !important;
}

.clp_header_dropdown .dropdown .dropdown-item {
  padding: 2px 10px;
}

.clp_header_dropdown .dropdown .dropdown-menu {
  transform: translate3d(-90px, 67px, 0px) !important;
}

.clp_header_dropdown .dropdown .dropdown-menu .dropdown-item:hover {
  border-bottom: none !important;
  background-color: #ffffff !important;
  color: #00afee;
}

// class attendance
.wh-120 {
  width: 120px;
  height: 120px;
}

.w-150 {
  width: 150px;
}

.class_atd_player {
  font-family: inherit;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
}

.selected_class_atd_player {
  color: #f05a28 !important;
}

.disabled_student_in_attendance {
  opacity: 0.5;
}

// collage
.collage_container {
  max-height: 500px;
  height: 500px;
}

.react-photo-gallery--gallery div {
  align-items: center;
  justify-content: center;
}

.react-photo-gallery--gallery div img {
  width: 200px !important;
  height: 200px !important;
  margin: 0 !important;
  border: 1px solid #00afee;
}

.tub_inbox_pending_card {
  height: 608px;
}

.card_h_half {
  height: 304px;
}

.card_h_quarter {
  height: 202px;
}

.clpContainer {
  margin-top: 80px;
}

// .eRmhhp {
//     border: none !important;
// }
// .iWjBbX + .sc-gqjmRU {
//     margin: 0px !important;
// }
// .eRmhhp + .sc-gZMcBi {
//     margin: 0px !important;
// }
// .iWjBbX {
//     border: 1px solid #00AFEE;
// }
.gallery_collage {
  column-gap: 0px;
  width: 33%;
}

.curriculum_reading_container {
  background-color: #ffffff;
  padding: 15px 10px;
  margin: 10px 0px;
}

.curriculum_nm_f_1 {
  color: #000000;
  opacity: 0.5;
  font-weight: 500;
  font-size: 12px;
}

.curriculum_nm_f_1:hover {
  color: #000000;
  opacity: 0.5;
}

.curriculum_nm_f_2 {
  color: #000000;
  font-weight: 600;
}

// assignment
.assignment_program_container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.horizontal_chrt_title {
  width: 365px;
  margin: 0 auto;
}

// analytics
.analytics_students {
  width: 60px;
  height: 60px;
  text-align: center;

  &:hover {
    img {
      border: 2px solid theme-color("primary");
    }

    h5 {
      color: theme-color("primary");
    }
  }

  img {
    border: 2px solid transparent;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  h5 {
    font-size: 12px;
    font-weight: 500;
    margin-top: 5px;
  }
}

.curriculum_assigned_dropdown .dropdown-menu {
  left: -70px !important;
  transform: translate3d(0px, 20px, 0px) !important;
}

.curriculum_assigned_dropdown .dropdown-menu.show {
  background: #d9d9d9 !important;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 3px;
  width: 60% !important;
  max-width: 30px;
  padding: 10px;
  border: none;
}

.curriculum_assigned_dropdown .dropdown-item {
  color: #7b7b7b;
  font-weight: 500;
  font-family: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
}

.curriculum_assigned_dropdown .dropdown-item:hover {
  border: none !important;
  color: #7b7b7b;
  background-color: transparent !important;
}

.assignment_calendar {
  padding: 5px 0px 5px 0px !important;

  .react-calendar__tile:disabled {
    color: #000000 !important;
    background-color: #c4c4c4 !important;
  }

  .react-calendar__navigation {
    margin-bottom: 1em;
    padding: 10px 7px 5px 7px;
    height: 75px;
    display: flex;
    justify-content: space-between;
  }

  .react-calendar__navigation button {
    background-color: #ffffff !important;
    min-width: 41px !important;
    height: 44px !important;
    margin: 8px !important;
  }

  .react-calendar__month-view__days {
    justify-content: center !important;
  }

  .react-calendar__month-view__weekdays {
    justify-content: center !important;
  }

  .react-calendar__month-view__weekdays__weekday {
    flex-basis: 13.2857% !important;
    max-width: 13.2857% !important;
    margin: 1px !important;
  }

  .react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none !important;
  }

  .react-calendar__viewContainer .react-calendar__tile {
    flex-basis: 12.2857% !important;
    max-width: 12.2857% !important;
    padding: 0.75em 0.1em !important;
    margin: 6px 1.6px !important;
    background: #ffffff;
    border-radius: 5px !important;
  }

  .react-calendar__tile--now {
    background: #00afee !important;
  }

  .assignment_present_date {
    background-color: #00afee5e !important;
  }

  .program_date {
    background-color: #f05a2833 !important;
  }

  .program_date:hover.react-calendar__tile:enabled:hover.react-calendar__tile:enabled:hover,
  .program_date.react-calendar__tile:enabled:focus {
    background-color: #f05a2833 !important;
    color: #000 !important;
  }

  .react-calendar__tile--active {
    position: relative;
    color: #000000 !important;
    // background-color: #ffffff !important;
  }

  .react-calendar__tile--active:not(.assignment_present_date):not(
      .react-calendar__tile--now
    ):not(.program_date) {
    position: relative;
    color: #000000 !important;
    background-color: #ffffff !important;
  }

  .assignment_present_date {
    background-color: #00afee5e !important;
  }

  .assignment_present_date:hover.react-calendar__tile:enabled:hover.react-calendar__tile:enabled:hover,
  .assignment_present_date.react-calendar__tile:enabled:focus {
    background-color: #00afee5e !important;
    color: #000 !important;
  }

  .react-calendar__tile--now:hover.react-calendar__tile:enabled:hover.react-calendar__tile:enabled:hover,
  .react-calendar__tile--now.react-calendar__tile:enabled:focus {
    background-color: #00afee !important;
  }
  .react-calendar__tile--now {
    background-color: #00afee !important;
  }

  .react-calendar__tile:enabled:hover.react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: #ffffff !important;
    color: #000 !important;
    font-weight: 300;
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background-color: #ffffff !important;
  }
  // .react-calendar__tile .react-calendar__tile--rangeStart {
  //     background-color: #ffffff !important;
  // }
  // .react-calendar__tile .react-calendar__tile--rangeEnd {
  //     background-color: #ffffff !important;
  // }
  // .react-calendar__tile--rangeStart {
  //     background-color: #ffffff !important;
  // }

  // .react-calendar__tile--rangeEnd {
  //     background-color: #ffffff !important;
  // }

  .react-calendar__tile--active::after {
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: #f05a28;
    top: 0;
    border-radius: 50%;
    right: 0;
  }
}

// skeleton loadder
.react-loading-skeleton {
  background-color: #ebebeb91;
}
.report {
  height: 100%;
}
.react-bootstrap-table-pagination .dropdown button {
  padding: 4px 9px;
}
.react-bootstrap-table-pagination .btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  border: 2px solid #000 !important;
  font-size: 14px;
  line-height: 1.5;
  font-family: inherit;
  background: #ffffff !important;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.react-bootstrap-table-pagination .btn-secondary:hover {
  color: #000000 !important;
  background-color: #0065d5;
  border-color: #005fc8;
}

.boxes {
  display: flex;
  align-self: flex-end;
  justify-content: space-between;
  padding-right : 1.9rem;
  .box-complete {
    min-width: 15px;
    height: 15px;
    background-color: #ed7d2b;
 }
 
 .box-incomplete {
  min-width: 15px;
  height: 15px;
   background-color: #00adef;
  }
  span {
    padding-left: 5px;
    padding-right: 5px;
    font-size: small;
  }
}
