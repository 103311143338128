@import "../../../node_modules/bootstrap/scss/mixins";
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";

html{
    @include media-breakpoint-only(xs) { 
        font-size: 12px;
    }
    @include media-breakpoint-only(sm) { 
        font-size: 12px;
    }
    @include media-breakpoint-only(md) { 
        font-size: 14px;
    }
    @include media-breakpoint-only(lg) { 
        font-size: 14px;
    }
    @include media-breakpoint-only(xlg) { 
        font-size: 14px;
    }
    @media screen and (min-width: 1900px){
        font-size: 18px;
    }
}

::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem;
}
::-webkit-scrollbar-track {
    background: #f1f1f1; 
}
::-webkit-scrollbar-thumb:hover {
    background: #00afee; 
}
::-webkit-scrollbar-thumb {
    background: #00afee; 
}
.rightScrollBar::-webkit-scrollbar {
    width: 0rem;
}
.leftScrollBar::-webkit-scrollbar {
    width: 0rem;
}
.selected_custom_collection_container::-webkit-scrollbar-thumb {
    background: #ded8d8; 
}